import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import map from 'lodash/map';
import first from 'lodash/first';
import findIndex from 'lodash/findIndex';
import cn from 'classnames';
import { connect } from 'react-redux';
import { getSecondsFromMidnight, SECONDS_IN_DAY } from 'helpers/datetime';
import MealTooltip from 'components/RelatedData/MealTooltip';
import ActivityTooltip from 'components/RelatedData/ActivityTooltip';
import PillTooltip from 'components/RelatedData/PillTooltip';
import InjectionTooltip from 'components/RelatedData/InjectionTooltip';
import * as actions from 'modules/PatientResults/actions';
import * as shapes from '../../shapes';
import Stack from './Stack';
import DayTimeline from './DayTimeline';
import Reading from './Reading';
import ReadingTooltip from './ReadingTooltip';
import StackedReadingsTooltip from './StackedReadingsTooltip';
import RelatedData from './RelatedData';
import { getStackedData } from './utils';
import styles from './DailyReadingsDistribution.pcss';


class DailyReadingsDistribution extends React.PureComponent {

  // eslint-disable-next-line consistent-return
  static getDerivedStateFromProps(props, state) {
    const { readings, relatedData } = props;
    if (!readings || !readings.length) {
      return null;
    }

    const { width } = state;
    if (width) {
      const { separateData: separateReadings, stackedData: stackedReadings } = getStackedData(readings, width);
      const { separateData: separateRelatedData, stackedData: stackedRelatedData } = getStackedData(relatedData, width);

      return {
        width,
        stackedReadings,
        separateReadings,
        separateRelatedData,
        stackedRelatedData,
      };
    }
  }

  static propTypes = {
    // Explicit props
    // eslint-disable-next-line react/no-unused-prop-types
    readings          : PropTypes.arrayOf(shapes.reading),
    relatedData       : PropTypes.array.isRequired,
    conversion        : PropTypes.object.isRequired,
    // Explicit actions
    setMeasurementElem: PropTypes.func,
  };


  constructor() {
    super();

    this.containerElement = React.createRef();

    this.state = {
      tooltip            : null,
      width              : 0,
      stackedReadings    : [],
      separateReadings   : [],
      stackedRelatedData : [],
      separateRelatedData: [],
    };
  }


  componentDidMount() {
    const { width } = this;
    this.setState({ width });
  }


  onShowTooltip(type, data, style) {
    this.setState({ tooltip: { type, data, style } });
  }


  onHideTooltip() {
    this.setState({ tooltip: null });
  }


  get width() {
    const boundingRect = this.containerElement.current.getBoundingClientRect();
    return boundingRect.width;
  }


  renderSeparateReading(reading) {
    const { timestamp, isActive } = reading;
    // eslint-disable-next-line no-mixed-operators
    const left = `${getSecondsFromMidnight(reading.timestamp) / SECONDS_IN_DAY * 100}%`;
    const style = { left };

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        key={`reading-${timestamp}`}
        className={
          cn(
            styles.dailyMeasurementsDistribution__reading,
            {
              [styles['dailyMeasurementsDistribution__reading--isActive']]: isActive,
            },
          )
        }
        style={style}
        onClick={
          () => {
            this.props.setMeasurementElem(reading);
          }
        }
        onMouseEnter={() => this.onShowTooltip('reading', reading, style)}
        onMouseLeave={() => this.onHideTooltip()}
      >
        <Reading reading={reading} />
      </div>
    );
  }


  renderSeparateReadings() {
    const { separateReadings } = this.state;
    return map(separateReadings, (reading) => this.renderSeparateReading(reading));
  }


  renderStackedReadings() {
    const { stackedReadings } = this.state;

    return map(stackedReadings, (stack) => {
      const firstReading = first(stack);
      const { timestamp } = firstReading;
      // eslint-disable-next-line no-mixed-operators
      const left = `${getSecondsFromMidnight(timestamp) / SECONDS_IN_DAY * 100}%`;
      const style = { left };

      const activeElementIndex = findIndex(stack, (reading) => reading.isActive);

      return (
        <div
          key={`stacked-reading-${timestamp}`}
          className={styles.dailyMeasurementsDistribution__readingStack}
          style={style}
        >
          <Stack
            data={stack}
            activeElementIndex={activeElementIndex}
            tooltipType="measurement"
          />
        </div>
      );
    });
  }

  renderStackedRelatedData() {
    const { stackedRelatedData } = this.state;

    return map(stackedRelatedData, (stack) => {
      const firstReading = first(stack);
      const { timestamp } = firstReading;
      // eslint-disable-next-line no-mixed-operators
      const left = `${getSecondsFromMidnight(timestamp) / SECONDS_IN_DAY * 100}%`;
      const style = { left };

      const activeElementIndex = findIndex(stack, (reading) => reading.isActive);
      return (
        <div
          key={`stacked-reading-${timestamp}`}
          className={styles.dailyMeasurementsDistribution__relatedDataStack}
          style={style}
        >
          <Stack
            data={stack}
            activeElementIndex={activeElementIndex}
            tooltipType="related"
          />
        </div>
      );
    });
  }


  renderRelatedData() {
    const { separateRelatedData } = this.state;
    return map(separateRelatedData, (data) => {
      const { timestamp, carbs } = data;
      // eslint-disable-next-line no-mixed-operators
      const left = `${getSecondsFromMidnight(timestamp) / SECONDS_IN_DAY * 100}%`;
      const style = { left };

      return (
        <div
          className={styles.dailyMeasurementsDistribution__relatedData}
          type="related-data"
          style={style}
          key={`${data.type}-${timestamp}-${carbs}`}
          onMouseEnter={() => this.onShowTooltip('relatedData', data, style)}
          onMouseLeave={() => this.onHideTooltip()}
        >
          <RelatedData
            data={data}
          />
        </div>
      );
    });
  }


  renderTooltip() {
    const { tooltip } = this.state;
    if (!tooltip) {
      return null;
    }

    const { type, data, style } = tooltip;

    if (type === 'reading') {
      return (
        <div className={styles.dailyMeasurementsDistribution__readingTooltip} style={style}>
          <ReadingTooltip reading={data} conversion={this.props.conversion} />
        </div>
      );
    }

    if (type === 'stackedReadings') {
      return (
        <div className={styles.dailyMeasurementsDistribution__readingStackTooltip} style={style}>
          <StackedReadingsTooltip readings={data} conversion={this.props.conversion} />
        </div>
      );
    }

    let relatedDataTooltip;
    switch (data.type) {
      case 'food':
        relatedDataTooltip = <MealTooltip meal={data} />;
        break;
      case 'activity':
        relatedDataTooltip = <ActivityTooltip activity={data} />;
        break;
      case 'pill':
        relatedDataTooltip = <PillTooltip pill={data} />;
        break;
      case 'injection':
        relatedDataTooltip = <InjectionTooltip injection={data} />;
        break;
      default:
        relatedDataTooltip = null;
    }

    return (
      <div className={styles.dailyMeasurementsDistribution__relatedDataTooltip} style={style}>
        { relatedDataTooltip }
      </div>
    );
  }


  render() {
    return (
      <div
        className={styles.dailyMeasurementsDistribution}
        ref={this.containerElement}
      >
        <DayTimeline />
        <div className={styles.dailyMeasurementsDistribution__markersLine}>
          { this.renderSeparateReadings() }
          { this.renderStackedReadings() }
          { this.renderRelatedData() }
          { this.renderStackedRelatedData() }
          { this.renderTooltip() }
        </div>
      </div>
    );
  }

}

const mapDispatchToProps = (dispatch) => ({
  setMeasurementElem: (measurement) => dispatch(actions.setMeasurement(measurement)),
});

const ConnectedDailyReadingsDistribution = connect(
  null,
  mapDispatchToProps,
)(DailyReadingsDistribution);


export default withStyles(styles)(ConnectedDailyReadingsDistribution);
