import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import findLast from 'lodash/findLast';
import map from 'lodash/map';
import get from 'lodash/get';
import partition from 'lodash/partition';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { formatDate, getNowAsUtc } from 'helpers/datetime';
import noteShape from 'shapes/noteShape';
import Tabs from 'components/Tabs';
import * as shapes from '../../shapes';
import messages from '../../messages';
import PrivateNoteInfo from '../PrivateNoteInfo';
import VisitNoteHeader from '../VisitNoteHeader';
import ReplyBtn from '../ReplyBtn';
import ReplyNoteForm from '../ReplyNoteForm';
import ReplyNote from '../ReplyNote';
import styles from '../VisitNotes.pcss';


class VisitNotes extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { notes } = props;
    const { replyNote } = state;

    if (replyNote && notes.length) {
      const note = find(
        notes,
        (n) => n.noteType === 'Note'
          && n.payload
          && n.payload.replyTo === replyNote.payload.replyTo,
      );
      if (note) {
        return { replyNote: null };
      }
    }

    return null;
  }


  static propTypes = {
    // Explicit props
    visit           : shapes.visit,
    notes           : PropTypes.arrayOf(noteShape),
    mode            : PropTypes.oneOf(['HCP', 'PWD']).isRequired,
    phiSet          : PropTypes.object,
    phiSetDocumentId: PropTypes.string,
    // Explicit action
    onStoreNotes    : PropTypes.func.isRequired,
  };


  constructor(props) {
    super(props);
    this.replyNoteRef = React.createRef();
    this.state = {
      activeView: 'shared',
      replyingTo: null,
      replyNote : null,
    };
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.replyingTo !== this.state.replyingTo && this.state.replyingTo && this.replyNoteRef.current) {
      this.replyNoteRef.current.focus();
    }
  }


  onCancel() {
    if (this.state.replyingTo) {
      this.setState({
        replyingTo: null,
        replyNote : null,
      });
    }
  }


  onChange(replyContent) {
    this.setState((state) => {
      const currentNote = findLast(
        this.props.notes,
        (n) => n.noteType === 'Note' && n.payload && n.replyTo === state.replyingTo.noteId,
      );
      const replyNote = {
        noteId   : get(currentNote, 'noteId', 'replyNote'),
        noteType : 'Note',
        content  : replyContent,
        timestamp: get(currentNote, 'timestamp', +getNowAsUtc().locale('en').format('X')),
        payload  : {
          replyTo: state.replyingTo.noteId,
          byPwd  : this.props.mode === 'PWD',
        },
      };
      return { replyNote };
    });
  }


  onReply(note) {
    this.setState({ replyingTo: note });
  }


  onSubmit(replyContent) {
    if (!replyContent) {
      this.setState({
        replyingTo: null,
        replyNote : null,
      });
      return;
    }
    const { phiSet, phiSetDocumentId } = this.props;
    const { phisetVisitId, metadata } = this.props.visit;
    const currentNote = findLast(
      this.props.notes,
      (n) => n.noteType === 'Note' && n.payload && n.payload.replyTo === this.state.replyingTo.noteId,
    );
    const updatedNote = {
      ...currentNote,
      noteType: 'Note',
      content : replyContent,
      payload : {
        replyTo  : this.state.replyingTo.noteId,
        byPwd    : this.props.mode === 'PWD',
        isPrivate: this.state.activeView === 'private',
      },
    };
    if (!updatedNote.timestamp) updatedNote.timestamp = +getNowAsUtc().locale('en').format('X');
    this.props.onStoreNotes([updatedNote], phiSet, phiSetDocumentId, phisetVisitId, metadata);
    this.setState({
      replyingTo: null,
    });
  }


  renderReplyNoteForm(note) {
    if (note !== this.state.replyingTo) {
      return null;
    }
    return (
      <ReplyNoteForm
        note={note}
        onCancel={() => this.onCancel()}
        onChange={(replyContent) => this.onChange(replyContent)}
        onSubmit={(replyContent) => this.onSubmit(replyContent)}
      />
    );
  }


  renderReplySection(note, replyNotes) {
    const replies = filter(replyNotes, (rn) => rn.payload && rn.payload.replyTo === note.noteId);
    const repliesLast = replies.length - 1;
    const canReply = !replies.length && note !== this.state.replyingTo;
    return (
      <>
        {
          canReply
          && (
            <div className="mt-2 text--small">
              <ReplyBtn
                note={note}
                onReply={(n) => this.onReply(n)}
              />
            </div>
          )
        }
        { this.renderReplyNoteForm(note) }
        {
          map(replies, (replyNote, idx) => (
            <ReplyNote
              key={replyNote.noteId}
              mode={this.props.mode}
              note={replyNote}
              replyNotes={replyNotes}
              replyingTo={this.state.replyingTo}
              isLast={idx === repliesLast}
              onCancel={() => this.onCancel()}
              onChange={(replyContent) => this.onChange(replyContent)}
              onReply={(n) => this.onReply(n)}
              onSubmit={(replyContent) => this.onSubmit(replyContent)}
            />
          ))
        }
      </>
    );
  }


  renderNote(note, replyNotes) {
    const { payload } = note;
    return (
      <React.Fragment key={note.noteId}>
        <div
          className={
            cn('pseudoEditorWrapper', {
              [styles.noteWithPayload]  : payload,
              [styles.noteWithDateRange]: payload && (payload.start || payload.end),
            })
          }
        >
          <VisitNoteHeader note={note} />
          <div className={`pseudoEditor ${styles.note}`}>
            { note.content }
          </div>
        </div>
        { this.renderReplySection(note, replyNotes) }
      </React.Fragment>
    );
  }


  renderMainNote(note, replyNotes) {
    if (!note) {
      return null;
    }
    return (
      <React.Fragment key={note.noteId}>
        <div className={styles.note}>
          { note.content }
        </div>
        { this.renderReplySection(note, replyNotes) }
      </React.Fragment>
    );
  }


  renderNotes(primaryNotes, replyNotes) {
    if (!primaryNotes.length) {
      return null;
    }
    return (
      <div className={styles.notes}>
        { map(primaryNotes, (note) => this.renderNote(note, replyNotes)) }
      </div>
    );
  }


  renderNotesSection() {
    const notes = [...this.props.notes];
    const commentNote = findLast(notes, { noteType: 'Comment' });
    if (commentNote) notes.pop();
    if (this.state.activeView === 'private') {
      const [primaryNotes, replyNotes] = partition(notes, (note) => note.noteType === 'Comment');
      return (
        <div className={styles.notesSection}>
          <div className={styles.notes}>
            <PrivateNoteInfo />
            { this.renderNotes(primaryNotes, commentNote) }
          </div>
          { commentNote && <hr className="form-divider" /> }
          { commentNote && this.renderMainNote(commentNote, replyNotes) }
        </div>
      );
    }
    const recommendationNote = findLast(notes, { noteType: 'Recommendation' });
    if (recommendationNote) notes.pop();
    const { replyingTo, replyNote } = this.state;
    const [replyNotes, primaryNotes] = partition(notes, (note) => note.noteType === 'Note');
    if (!replyingTo && replyNote) replyNotes.push(replyNote);
    return (
      <div className={styles.notesSection}>
        { this.renderNotes(primaryNotes, replyNotes) }
        { recommendationNote && !!notes.length && <hr className="form-divider" /> }
        { recommendationNote && this.renderMainNote(recommendationNote, replyNotes) }
      </div>
    );
  }


  renderTabs() {
    if (this.props.mode === 'PWD') {
      return null;
    }
    return (
      <Tabs
        activeItem={this.state.activeView}
        items={['shared', 'private']}
        messages={messages.tabs}
        action={(activeView) => this.setState({ activeView })}
      />
    );
  }


  renderVisitInfo() {
    const { visit } = this.props;
    const hcp = get(visit, 'metadata.createdBy', {});
    const visitDate = get(visit, 'metadata.visitDate');
    const { firstName, lastName } = hcp;
    return (
      <ul className={styles.visitInfo}>
        <li className="col-auto">
          <span className="caption d-block mb-2"><FormattedMessage {...messages.labels.hcp} /></span>
          <span className="d-block">{ firstName } { lastName }</span>
        </li>
        <li className="col-auto">
          <span className="caption d-block mb-2"><FormattedMessage {...messages.labels.date} /></span>
          <span className="d-block">{ formatDate(visitDate) }</span>
        </li>
      </ul>
    );
  }


  render() {
    return (
      <div>
        { this.renderVisitInfo() }
        { this.renderTabs() }
        { this.renderNotesSection() }
      </div>
    );
  }

}


export default withStyles(styles)(VisitNotes);
