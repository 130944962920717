// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3WVrd{display:-ms-flexbox;display:flex;gap:8px;padding:12px 0;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-ms-flex-pack:distribute;justify-content:space-around}._2o91W{height:2px;background-color:#e0e8f2;border-radius:1px;width:100%}._15YJY{background-color:#30a8ff}", ""]);
// Exports
exports.locals = {
	"dots": "_3WVrd",
	"dots__dot": "_2o91W",
	"dots__dot--active": "_15YJY"
};
module.exports = exports;
