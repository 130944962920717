import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import DownloadWithCircle from 'svg/download-with-circle.svg';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';
import { isDownloaderUpdated } from '../../selectors';


interface ActionsProps {
  onSetComponent: (componentName: string) => void,
}

interface Props {
  onSetComponent: (componentName: string) => void,
  onSetHeaderMessage: (headerMessage?: MessageDescriptor) => void,
}


const useIsDownloaderAppCloseNeeded = () => {
  const status = useSelector(selectors.status);
  return status === constants.DOWNLOADER_STATUSES.UPDATE_ENDPOINT_NOT_FOUND;
};


const appName = <b>{ constants.DOWNLOADER_TRAY_APP_NAME }</b>;
const b = (chunk) => <b>{ chunk }</b>;


const CloseAppInfo: FC = () => {
  const isDownloaderAppCloseNeeded = useIsDownloaderAppCloseNeeded();
  if (!isDownloaderAppCloseNeeded) {
    return null;
  }

  return (
    <div className={styles.downloader_outdated__close_app_info}>
      <FormattedMessage {...messages.downloaderOutdated.closeAppInfo} values={{ appName }} />
      <FormattedMessage {...messages.downloaderOutdated.clickUpdateNow} values={{ b }} />
    </div>
  );
};


const Image: FC = () => {
  const isDownloaderAppCloseNeeded = useIsDownloaderAppCloseNeeded();
  if (isDownloaderAppCloseNeeded) {
    return <img src="/assets/img/close_downloader_tray.png" alt="Close Downloader tray" />;
  }

  return <DownloadWithCircle className={styles.downloader_outdated__loader_icon} />;
};


const Actions: FC<ActionsProps> = ({ onSetComponent }) => {
  const isDownloaderAppCloseNeeded = useIsDownloaderAppCloseNeeded();
  const updateDownloader = useAction(actions.updateDownloader);

  const onUpdate = () => {
    if (isDownloaderAppCloseNeeded) {
      onSetComponent('Initializing');
      return;
    }
    updateDownloader();
  };

  return (
    <div className="modal__actions row">
      <Button
        type="button"
        styleModifier="primary"
        labelMessage={messages.buttons.updateNow}
        className="btn--block btn--filled"
        onClick={onUpdate}
      />
    </div>
  );
};


const DownloaderOutdated: FC<Props> = ({ onSetComponent, onSetHeaderMessage }) => {
  const status = useSelector(selectors.status);
  const logEvent = useAction(actions.logEvent, 'UpgradePromptDisplayed');
  const isUpdated = useSelector(isDownloaderUpdated);
  const isUpdating = useSelector(selectors.isUpdatingSelector);

  useEffect(() => {
    onSetHeaderMessage(messages.addPatient.downloaderOutdated);
    logEvent();
    return () => onSetHeaderMessage();
  }, []);

  useEffect(() => {
    if (isUpdated) {
      onSetComponent('Initializing');
    }
  }, [isUpdated]);

  useEffect(() => {
    if (status === constants.DOWNLOADER_STATUSES.UPDATE_ERROR) {
      onSetComponent('Initializing');
    }
    if (status === constants.DOWNLOADER_STATUSES.UPDATE_BAD_REQUEST_ERROR) {
      onSetComponent('UpdateError');
    }

  }, [status]);

  if (isUpdating) {
    return (
      <div className={styles.modal__content}>
        <div className={styles.illustration}>
          <img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" />
        </div>
        <p className="modal__info text--center mb-5"><FormattedMessage {...messages.initializing.waiting} /></p>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.downloader_outdated}>
        <div className="brand__paragraph">
          <FormattedMessage
            {...messages.downloaderOutdated.info}
            values={{ appName: <b>{ constants.DOWNLOADER_APP_NAME }</b> }}
          />
          <CloseAppInfo />
        </div>
      </div>
      <div className={styles.downloader_outdated__img}>
        <Image />
      </div>
      <Actions {...{ onSetComponent }} />
    </div>
  );
};

export default DownloaderOutdated;
