import forEach from 'lodash/forEach';
import forOwn from 'lodash/forOwn';
import includes from 'lodash/includes';
import pick from 'lodash/pick';
import reject from 'lodash/reject';
import moment from 'moment/moment';
import { SIGN_OUT_SUCCESS } from 'modules/Account/actionTypes';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


const initialState = {
  mode                     : constants.MODES[0],
  deviceMode               : constants.DEVICES_MODES[0],
  calculationFormula       : constants.CALCULATION_FORMULAS[0],
  aggregateBy              : constants.AGGREGATE_BY[0],
  groupBy                  : constants.GROUP_BY[0],
  fromImports              : null,
  fromImportsRange         : null,
  isTrendChartLineEnabled  : true,
  dashboardLayout          : constants.DASHBOARD_LAYOUTS.basic,
  resultsIsInProgress      : true,
  reportsState             : {},
  reportTypes              : [],
  gestationalReportSettings: [
    {
      id   : 'night_am',
      value: moment().hour(0).minute(0)
        .format('HH:mm'),
    },
    {
      id   : 'breakfast',
      value: moment().hour(6).minute(0)
        .format('HH:mm'),
    },
    {
      id   : 'lunch',
      value: moment().hour(12).minute(0)
        .format('HH:mm'),
    },
    {
      id   : 'dinner',
      value: moment().hour(16).minute(0)
        .format('HH:mm'),
    },
    {
      id   : 'night_pm',
      value: moment().hour(20).minute(0)
        .format('HH:mm'),
    },
  ],
  measurement: null,
};


function getFromImportsRange(fromImports, imports) {
  let maxResultDate = 0;
  let minResultDate = 0;

  forOwn(imports, (value) => {
    forEach(value.documents, (document) => {
      if (includes(fromImports, document.importDocumentId)) {
        if (maxResultDate < document.maxResultDate) {
          maxResultDate = document.maxResultDate;
        }
        if (document.minResultDate && (!minResultDate || minResultDate > document.minResultDate)) {
          minResultDate = document.minResultDate;
        }
      }
    });
  });

  return maxResultDate && minResultDate
    ? { maxResultDate, minResultDate }
    : null;
}

// eslint-disable-next-line default-param-last
export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.SET_MODE: {
      const { mode } = action.payload;
      if (!includes(constants.MODES, mode)) {
        return state;
      }
      const options = state.deviceMode === 'CGM'
        ? []
        : pick(initialState, ['calculationFormula', 'aggregateBy', 'groupBy']);
      if (mode === 'AGGREGATED') {
        options.calculationFormula = constants.CALCULATION_FORMULAS[1];
      }
      return {
        ...state,
        ...options,
        mode,
      };
    }

    case actionTypes.SET_DEVICE_MODE: {
      const { deviceMode } = action.payload;
      let options;
      switch (deviceMode) {
        case 'CGM':
          options = {
            calculationFormula: constants.CALCULATION_FORMULAS_CGM[0],
            groupBy           : constants.GROUP_BY_CGM[0],
            mode              : constants.MODES[0],
            dashboardLayout   : constants.DASHBOARD_LAYOUTS.agp,
          };
          break;

        case 'AGP':
          options = {
            calculationFormula: constants.CALCULATION_FORMULAS[0],
            groupBy           : constants.GROUP_BY_CGM[0],
            mode              : constants.MODES[2],
            dashboardLayout   : constants.DASHBOARD_LAYOUTS.agpBgm,
          };
          break;

        default:
          options = {
            calculationFormula: constants.CALCULATION_FORMULAS[0],
            groupBy           : constants.GROUP_BY[0],
            mode              : constants.MODES[0],
            dashboardLayout   : constants.DASHBOARD_LAYOUTS.basic,
          };
          break;
      }

      return {
        ...state,
        deviceMode,
        ...options,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_CALCULATION_FORMULA: {
      const { calculationFormula } = action.payload;
      if (!includes(constants.CALCULATION_FORMULAS, calculationFormula)
        && !includes(constants.CALCULATION_FORMULAS_CGM, calculationFormula)) {
        return state;
      }
      return {
        ...state,
        calculationFormula,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_AGGREGATE_BY: {
      const { aggregateBy } = action.payload;
      if (!includes(constants.AGGREGATE_BY, aggregateBy)) {
        return state;
      }
      return {
        ...state,
        aggregateBy,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_GROUP_BY: {
      const { groupBy } = action.payload;
      if (!includes(constants.GROUP_BY, groupBy) && !includes(constants.GROUP_BY_CGM, groupBy)) {
        return state;
      }
      return {
        ...state,
        groupBy,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CHANGE_FROM_IMPORTS: {
      const { importDocumentId, imports } = action.payload;
      let fromImports;
      if (includes(state.fromImports, importDocumentId)) {
        fromImports = reject(state.fromImports, (idi) => idi === importDocumentId);
        if (!fromImports.length) {
          fromImports = null;
        }
      } else {
        fromImports = [...(state.fromImports || []), importDocumentId];
      }

      const fromImportsRange = getFromImportsRange(fromImports, imports);

      return {
        ...state,
        fromImports,
        fromImportsRange,
      };
    }


    case actionTypes.CLEAR_FROM_IMPORTS: {
      return {
        ...state,
        fromImports     : null,
        fromImportsRange: null,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_IS_TREND_CHART_LINE_ENABLED: {
      const { isTrendChartLineEnabled } = action.payload;
      return {
        ...state,
        isTrendChartLineEnabled,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_DASHBOARD_LAYOUT: {
      const { layout } = action.payload;
      return {
        ...state,
        dashboardLayout: layout,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_RESULTS_IN_PROGRESS: {
      const { isInProgress } = action.payload;
      return {
        ...state,
        resultsIsInProgress: isInProgress,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.DUMP_REPORTS_STATE: {
      const { reportsState } = action.payload;
      return {
        ...state,
        reportsState,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.STORE_SELECTED_REPORT_TYPES: {
      const { reportTypes } = action.payload;
      return {
        ...state,
        reportTypes,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_GESTATIONAL_REPORT_SETTINGS: {
      const { gestationalReportSettings } = action.payload;
      return {
        ...state,
        gestationalReportSettings,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_CLICKED_MEASUREMENT: {
      const { measurement } = action.payload;
      return {
        ...state,
        measurement,
      };
    }

    //------------------------------------------------------------------------------------------------------------------
    default: {
      return state;
    }

  }
}
