import { defineMessages } from 'react-intl';


export default defineMessages({
  manual : { id: 'charts.manual' },
  average: { id: 'charts.average' },

  labels: {
    medications  : { id: 'charts.labels.medications' },
    activities   : { id: 'charts.labels.activities' },
    injectionUnit: { id: 'charts.labels.injectionUnit' },
    activityUnit : { id: 'charts.labels.activityUnit' },
    count        : { id: 'charts.labels.count' },
    height       : { id: 'charts.labels.height' },
    weight       : { id: 'charts.labels.weight' },
    hours        : { id: 'charts.labels.hours' },
    minutes      : { id: 'charts.labels.minutes' },
    longTerm     : { id: 'charts.labels.longTerm' },
    fastTerm     : { id: 'charts.labels.fastTerm' },
    carbs        : { id: 'charts.labels.carbs' },
    nutrientUnit : { id: 'charts.labels.nutrientUnit' },
  },

  placeholders: {
    additionalChart: { id: 'charts.placeholders.additionalChart' },
  },
});
