// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3qCj1{-ms-flex-pack:justify;justify-content:space-between;color:#000;font-weight:700}._3qCj1,._1j1Dd{display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-ms-flex-align:center;align-items:center}._1j1Dd{padding:0 8px}._1j1Dd svg{height:16px;height:1.6rem;fill:#30a8ff;width:16px;width:1.6rem;margin-right:8px}.q4ipw{width:24px;height:24px;border-radius:4px;background-color:#f5f9ff;cursor:pointer;text-align:center}.q4ipw svg{fill:#30a8ff;height:12px;width:7px;position:relative;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%)}._3nXHA{background-color:#f4f7fa}._3nXHA svg{fill:#95a1bd}.k7o6D svg{-webkit-transform:rotate(180deg) translateY(47%);-ms-transform:rotate(180deg) translateY(47%);transform:rotate(180deg) translateY(47%)}", ""]);
// Exports
exports.locals = {
	"nav": "_3qCj1",
	"nav__label": "_1j1Dd",
	"nav__navbutton": "q4ipw",
	"nav__navbutton--disabled": "_3nXHA",
	"nav__navbutton--left": "k7o6D"
};
module.exports = exports;
