import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './styles.pcss';


const AppLoader: FC = () => {
  useStyles(styles);

  return (
    <div className={styles.AppLoader}>
      <svg xmlns="http://www.w3.org/2000/svg" width="577.59" height="107.08" viewBox="392 0 577.59 107.08">
        <defs>
          <clipPath id="clippath">
            <rect id="maskavect" className="cls-3" x="420.55" width="452.87" height="107.08" />
          </clipPath>
        </defs>
        <g id="kropki">
          <g id="maska">
            <g className="cls-18">
              <g id="red">
                <g id="re1">
                  <g>
                    <circle className="cls-24" cx="864.07" cy="36.88" r="1.84" />
                    <circle className="cls-24" cx="842.22" cy="23.91" r="2.81" />
                    <circle className="cls-24" cx="817.28" cy="62.26" r="2.59" />
                    <circle className="cls-24" cx="788.88" cy="53.85" r="2.98" />
                    <circle className="cls-24" cx="767.44" cy="46.76" r="3" />
                    <circle className="cls-24" cx="737.53" cy="63.94" r="2.83" />
                    <circle className="cls-24" cx="716.17" cy="76.77" r="3.82" />
                    <circle className="cls-24" cx="688.96" cy="53.94" r="1.02" />
                    <circle className="cls-24" cx="665.73" cy="40.65" r="1.51" />
                    <circle className="cls-24" cx="646.09" cy="64.24" r="3.06" />
                    <circle className="cls-24" cx="621.34" cy="71.45" r="1.17" />
                    <circle className="cls-24" cx="608.59" cy="35.71" r="3.07" />
                    <circle className="cls-24" cx="587.21" cy="32.14" r="5.03" />
                    <circle className="cls-24" cx="560.31" cy="60.37" r="1.3" />
                    <circle className="cls-24" cx="538.02" cy="63.69" r="4.56" />
                    <circle className="cls-24" cx="511.24" cy="60.05" r="4.34" />
                    <circle className="cls-24" cx="498.08" cy="41.39" r="4.49" />
                    <circle className="cls-24" cx="473.14" cy="25.76" r="1.68" />
                  </g>
                  <g>
                    <circle className="cls-24" cx="424.07" cy="36.88" r="1.84" />
                    <circle className="cls-24" cx="402.22" cy="23.91" r="2.81" />
                    <circle className="cls-24" cx="377.28" cy="62.26" r="2.59" />
                    <circle className="cls-24" cx="348.88" cy="53.85" r="2.98" />
                    <circle className="cls-24" cx="327.44" cy="46.76" r="3" />
                    <circle className="cls-24" cx="297.53" cy="63.94" r="2.83" />
                    <circle className="cls-24" cx="276.17" cy="76.77" r="3.82" />
                    <circle className="cls-24" cx="248.96" cy="53.94" r="1.02" />
                    <circle className="cls-24" cx="225.73" cy="40.65" r="1.51" />
                    <circle className="cls-24" cx="206.09" cy="64.24" r="3.06" />
                    <circle className="cls-24" cx="181.34" cy="71.45" r="1.17" />
                    <circle className="cls-24" cx="168.59" cy="35.71" r="3.07" />
                    <circle className="cls-24" cx="147.21" cy="32.14" r="5.03" />
                    <circle className="cls-24" cx="120.31" cy="60.37" r="1.3" />
                    <circle className="cls-24" cx="98.02" cy="63.69" r="4.56" />
                    <circle className="cls-24" cx="71.24" cy="60.05" r="4.34" />
                    <circle className="cls-24" cx="58.08" cy="41.39" r="4.49" />
                    <circle className="cls-24" cx="33.14" cy="25.76" r="1.68" />
                  </g>
                </g>
                <g id="re2">
                  <circle className="cls-24" cx="852.44" cy="49.96" r="4.47" />
                  <circle className="cls-24" cx="828.36" cy="53.44" r="1.01" />
                  <circle className="cls-24" cx="804.37" cy="25.04" r="3.03" />
                  <circle className="cls-24" cx="780.96" cy="34.93" r="2.27" />
                  <circle className="cls-24" cx="759.16" cy="73.67" r="3.47" />
                  <circle className="cls-24" cx="733.96" cy="52.66" r="3.4" />
                  <circle className="cls-24" cx="703.01" cy="31.93" r="1.89" />
                  <circle className="cls-24" cx="678.16" cy="83.61" r="2.83" />
                  <circle className="cls-24" cx="662.04" cy="38.85" r="3.86" />
                  <circle className="cls-24" cx="642.69" cy="78.46" r=".68" />
                  <circle className="cls-24" cx="617.34" cy="28.17" r="3" />
                  <circle className="cls-24" cx="599.58" cy="59.82" r="5.15" />
                  <circle className="cls-24" cx="582.33" cy="56.16" r="2.06" />
                  <circle className="cls-24" cx="547.85" cy="42.3" r="1.84" />
                  <circle className="cls-24" cx="528.9" cy="79.18" r="1.01" />
                  <circle className="cls-24" cx="505.45" cy="40.02" r="5.06" />
                  <circle className="cls-24" cx="480.53" cy="27.83" r="2.25" />
                  <circle className="cls-24" cx="456.45" cy="37.85" r=".85" />
                  <circle className="cls-24" cx="412.44" cy="49.96" r="4.47" />
                  <circle className="cls-24" cx="388.36" cy="53.44" r="1.01" />
                  <circle className="cls-24" cx="364.37" cy="25.04" r="3.03" />
                  <circle className="cls-24" cx="340.96" cy="34.93" r="2.27" />
                  <circle className="cls-24" cx="319.16" cy="73.67" r="3.47" />
                  <circle className="cls-24" cx="293.96" cy="52.66" r="3.4" />
                  <circle className="cls-24" cx="263.01" cy="31.93" r="1.89" />
                  <circle className="cls-24" cx="238.16" cy="83.61" r="2.83" />
                  <circle className="cls-24" cx="222.04" cy="38.85" r="3.86" />
                  <circle className="cls-24" cx="202.69" cy="78.46" r=".68" />
                  <circle className="cls-24" cx="177.34" cy="28.17" r="3" />
                  <circle className="cls-24" cx="159.58" cy="59.82" r="5.15" />
                  <circle className="cls-24" cx="142.33" cy="56.16" r="2.06" />
                  <circle className="cls-24" cx="107.85" cy="42.3" r="1.84" />
                  <circle className="cls-24" cx="88.9" cy="79.18" r="1.01" />
                  <circle className="cls-24" cx="65.45" cy="40.02" r="5.06" />
                  <circle className="cls-24" cx="40.53" cy="27.83" r="2.25" />
                  <circle className="cls-24" cx="16.45" cy="37.85" r=".85" />
                </g>
                <g id="re3">
                  <circle className="cls-24" cx="878.87" cy="36.98" r="1.13" />
                  <circle className="cls-24" cx="857.98" cy="76.54" r="3.68" />
                  <circle className="cls-24" cx="835.67" cy="41.03" r="3.59" />
                  <circle className="cls-24" cx="811.08" cy="31.91" r="3.42" />
                  <circle className="cls-24" cx="785.2" cy="44.22" r="3.06" />
                  <circle className="cls-24" cx="770.23" cy="49.21" r="3.4" />
                  <circle className="cls-24" cx="751.9" cy="84.03" r="3.09" />
                  <circle className="cls-24" cx="722.87" cy="74.37" r="5.17" />
                  <circle className="cls-24" cx="697.13" cy="27.99" r="3.08" />
                  <circle className="cls-24" cx="673.55" cy="77.18" r="2.73" />
                  <circle className="cls-24" cx="656.85" cy="79.48" r="2.37" />
                  <circle className="cls-24" cx="635.99" cy="38.96" r="4.22" />
                  <circle className="cls-24" cx="614.07" cy="63.7" r="3.28" />
                  <circle className="cls-24" cx="594.29" cy="65.11" r="1.25" />
                  <circle className="cls-24" cx="567.83" cy="68.02" r="1.23" />
                  <circle className="cls-24" cx="541.71" cy="63.65" r="2.48" />
                  <circle className="cls-24" cx="522.31" cy="72.19" r="1.87" />
                  <circle className="cls-24" cx="485.97" cy="61.79" r="1.68" />
                  <circle className="cls-24" cx="459.38" cy="41.85" r="3.9" />
                  <circle className="cls-24" cx="448.3" cy="51.07" r=".95" />
                  <circle className="cls-24" cx="438.87" cy="36.98" r="1.13" />
                  <circle className="cls-24" cx="417.98" cy="76.54" r="3.68" />
                  <circle className="cls-24" cx="395.67" cy="41.03" r="3.59" />
                  <circle className="cls-24" cx="371.08" cy="31.91" r="3.42" />
                  <circle className="cls-24" cx="345.2" cy="44.22" r="3.06" />
                  <circle className="cls-24" cx="330.23" cy="49.21" r="3.4" />
                  <circle className="cls-24" cx="311.9" cy="84.03" r="3.09" />
                  <circle className="cls-24" cx="282.87" cy="74.37" r="5.17" />
                  <circle className="cls-24" cx="257.13" cy="27.99" r="3.08" />
                  <circle className="cls-24" cx="233.55" cy="77.18" r="2.73" />
                  <circle className="cls-24" cx="216.85" cy="79.48" r="2.37" />
                  <circle className="cls-24" cx="195.99" cy="38.96" r="4.22" />
                  <circle className="cls-24" cx="174.07" cy="63.7" r="3.28" />
                  <circle className="cls-24" cx="154.29" cy="65.11" r="1.25" />
                  <circle className="cls-24" cx="127.83" cy="68.02" r="1.23" />
                  <circle className="cls-24" cx="101.71" cy="63.65" r="2.48" />
                  <circle className="cls-24" cx="82.31" cy="72.19" r="1.87" />
                  <circle className="cls-24" cx="45.97" cy="61.79" r="1.68" />
                  <circle className="cls-24" cx="19.38" cy="41.85" r="3.9" />
                  <circle className="cls-24" cx="8.3" cy="51.07" r=".95" />
                </g>
                <g id="re4">
                  <circle className="cls-24" cx="871.14" cy="55.03" r="1.1" />
                  <circle className="cls-24" cx="849.81" cy="73.23" r="1.15" />
                  <circle className="cls-24" cx="821.1" cy="30.33" r="1.36" />
                  <circle className="cls-24" cx="795.99" cy="79.95" r="2.8" />
                  <circle className="cls-24" cx="778.34" cy="47.63" r="3.81" />
                  <circle className="cls-24" cx="743.31" cy="38.67" r="4.99" />
                  <circle className="cls-24" cx="728.67" cy="69.79" r="3.39" />
                  <circle className="cls-24" cx="709.55" cy="49.49" r="4.99" />
                  <circle className="cls-24" cx="685.85" cy="82.28" r="2.84" />
                  <circle className="cls-24" cx="670.18" cy="78.42" r="3.68" />
                  <circle className="cls-24" cx="649.87" cy="55.56" r="3.47" />
                  <circle className="cls-24" cx="627.69" cy="66.65" r="4.53" />
                  <circle className="cls-24" cx="603.33" cy="79.33" r="1.86" />
                  <circle className="cls-24" cx="591.54" cy="42.67" r=".91" />
                  <circle className="cls-24" cx="575.75" cy="64.42" r="3.45" />
                  <circle className="cls-24" cx="553.59" cy="47.46" r=".99" />
                  <circle className="cls-24" cx="533.93" cy="70.48" r="1.14" />
                  <circle className="cls-24" cx="515.49" cy="83.63" r="3.89" />
                  <circle className="cls-24" cx="491.52" cy="45.02" r="1.27" />
                  <circle className="cls-24" cx="466.37" cy="53.36" r="4.59" />
                  <circle className="cls-24" cx="441.36" cy="63.17" r="1.36" />
                  <circle className="cls-24" cx="431.14" cy="55.03" r="1.1" />
                  <circle className="cls-24" cx="409.81" cy="73.23" r="1.15" />
                  <circle className="cls-24" cx="381.1" cy="30.33" r="1.36" />
                  <circle className="cls-24" cx="355.99" cy="79.95" r="2.8" />
                  <circle className="cls-24" cx="338.34" cy="47.63" r="3.81" />
                  <circle className="cls-24" cx="303.31" cy="38.67" r="4.99" />
                  <circle className="cls-24" cx="288.67" cy="69.79" r="3.39" />
                  <circle className="cls-24" cx="269.55" cy="49.49" r="4.99" />
                  <circle className="cls-24" cx="245.85" cy="82.28" r="2.84" />
                  <circle className="cls-24" cx="230.18" cy="78.42" r="3.68" />
                  <circle className="cls-24" cx="209.87" cy="55.56" r="3.47" />
                  <circle className="cls-24" cx="187.69" cy="66.65" r="4.53" />
                  <circle className="cls-24" cx="163.33" cy="79.33" r="1.86" />
                  <circle className="cls-24" cx="151.54" cy="42.67" r=".91" />
                  <circle className="cls-24" cx="135.75" cy="64.42" r="3.45" />
                  <circle className="cls-24" cx="113.59" cy="47.46" r=".99" />
                  <circle className="cls-24" cx="93.93" cy="70.48" r="1.14" />
                  <circle className="cls-24" cx="75.49" cy="83.63" r="3.89" />
                  <circle className="cls-24" cx="51.52" cy="45.02" r="1.27" />
                  <circle className="cls-24" cx="26.37" cy="53.36" r="4.59" />
                  <circle className="cls-24" cx="1.36" cy="63.17" r="1.36" />
                </g>
              </g>
              <g id="ora">
                <g id="or4">
                  <circle className="cls-19" cx="870.69" cy="65.14" r=".74" />
                  <circle className="cls-19" cx="848.07" cy="27.9" r="4.07" />
                  <circle className="cls-19" cx="833.88" cy="55.37" r="1.31" />
                  <circle className="cls-19" cx="805.94" cy="52.82" r="2.68" />
                  <circle className="cls-19" cx="773.69" cy="59.89" r="3.41" />
                  <circle className="cls-19" cx="751.61" cy="40.72" r=".9" />
                  <circle className="cls-19" cx="724.21" cy="62.25" r="3.22" />
                  <circle className="cls-19" cx="706.17" cy="82.01" r="1.65" />
                  <circle className="cls-19" cx="685.6" cy="34.68" r="2.3" />
                  <circle className="cls-19" cx="666.94" cy="78.86" r=".95" />
                  <circle className="cls-19" cx="637.44" cy="81.86" r="1.58" />
                  <circle className="cls-19" cx="602.27" cy="66.4" r="4.23" />
                  <circle className="cls-19" cx="594.4" cy="46.17" r="4.59" />
                  <circle className="cls-19" cx="560.27" cy="51.77" r="3.04" />
                  <circle className="cls-19" cx="544.19" cy="70.92" r="3" />
                  <circle className="cls-19" cx="527.67" cy="36.11" r="4.05" />
                  <circle className="cls-19" cx="508.26" cy="35.76" r="4.74" />
                  <circle className="cls-19" cx="460.93" cy="58.2" r="5" />
                  <circle className="cls-19" cx="430.69" cy="65.14" r=".74" />
                  <circle className="cls-19" cx="408.07" cy="27.9" r="4.07" />
                  <circle className="cls-19" cx="393.88" cy="55.37" r="1.31" />
                  <circle className="cls-19" cx="365.94" cy="52.82" r="2.68" />
                  <circle className="cls-19" cx="333.69" cy="59.89" r="3.41" />
                  <circle className="cls-19" cx="311.61" cy="40.72" r=".9" />
                  <circle className="cls-19" cx="284.21" cy="62.25" r="3.22" />
                  <circle className="cls-19" cx="266.17" cy="82.01" r="1.65" />
                  <circle className="cls-19" cx="245.6" cy="34.68" r="2.3" />
                  <circle className="cls-19" cx="226.94" cy="78.86" r=".95" />
                  <circle className="cls-19" cx="197.44" cy="81.86" r="1.58" />
                  <circle className="cls-19" cx="162.27" cy="66.4" r="4.23" />
                  <circle className="cls-19" cx="154.4" cy="46.17" r="4.59" />
                  <circle className="cls-19" cx="120.27" cy="51.77" r="3.04" />
                  <circle className="cls-19" cx="104.19" cy="70.92" r="3" />
                  <circle className="cls-19" cx="87.67" cy="36.11" r="4.05" />
                  <circle className="cls-19" cx="68.26" cy="35.76" r="4.74" />
                  <circle className="cls-19" cx="20.93" cy="58.2" r="5" />
                </g>
                <g id="or3">
                  <circle className="cls-19" cx="855.88" cy="76.71" r="3.16" />
                  <circle className="cls-19" cx="837.06" cy="53.48" r="3.37" />
                  <circle className="cls-19" cx="826.89" cy="75.08" r="2.26" />
                  <circle className="cls-19" cx="785.17" cy="63.3" r="5.16" />
                  <circle className="cls-19" cx="766.82" cy="32.19" r="1.76" />
                  <circle className="cls-19" cx="729.18" cy="61.3" r="4.67" />
                  <circle className="cls-19" cx="712.19" cy="49.64" r="4.89" />
                  <circle className="cls-19" cx="699.65" cy="22.99" r="4.03" />
                  <circle className="cls-19" cx="671.72" cy="59.74" r="1.45" />
                  <circle className="cls-19" cx="650.91" cy="44.91" r="3.59" />
                  <circle className="cls-19" cx="625.36" cy="57.84" r="3.29" />
                  <circle className="cls-19" cx="599.56" cy="45.61" r="4.49" />
                  <circle className="cls-19" cx="578.95" cy="39.77" r="2.08" />
                  <circle className="cls-19" cx="550.77" cy="67.95" r="2.76" />
                  <circle className="cls-19" cx="531.9" cy="63.49" r="4.44" />
                  <circle className="cls-19" cx="516.43" cy="46.82" r="1.79" />
                  <circle className="cls-19" cx="497.66" cy="29.41" r="4.45" />
                  <circle className="cls-19" cx="453.98" cy="36.28" r="4.43" />
                  <circle className="cls-19" cx="415.88" cy="76.71" r="3.16" />
                  <circle className="cls-19" cx="397.06" cy="53.48" r="3.37" />
                  <circle className="cls-19" cx="386.89" cy="75.08" r="2.26" />
                  <circle className="cls-19" cx="345.17" cy="63.3" r="5.16" />
                  <circle className="cls-19" cx="326.82" cy="32.19" r="1.76" />
                  <circle className="cls-19" cx="289.18" cy="61.3" r="4.67" />
                  <circle className="cls-19" cx="272.19" cy="49.64" r="4.89" />
                  <circle className="cls-19" cx="259.65" cy="22.99" r="4.03" />
                  <circle className="cls-19" cx="231.72" cy="59.74" r="1.45" />
                  <circle className="cls-19" cx="210.91" cy="44.91" r="3.59" />
                  <circle className="cls-19" cx="185.36" cy="57.84" r="3.29" />
                  <circle className="cls-19" cx="159.56" cy="45.61" r="4.49" />
                  <circle className="cls-19" cx="138.95" cy="39.77" r="2.08" />
                  <circle className="cls-19" cx="110.77" cy="67.95" r="2.76" />
                  <circle className="cls-19" cx="91.9" cy="63.49" r="4.44" />
                  <circle className="cls-19" cx="76.43" cy="46.82" r="1.79" />
                  <circle className="cls-19" cx="57.66" cy="29.41" r="4.45" />
                  <circle className="cls-19" cx="13.98" cy="36.28" r="4.43" />
                </g>
                <g id="or2">
                  <circle className="cls-19" cx="877.65" cy="42.32" r="2.35" />
                  <circle className="cls-19" cx="873.29" cy="57.49" r="2.4" />
                  <circle className="cls-19" cx="862.87" cy="54.85" r="2.58" />
                  <circle className="cls-19" cx="841.79" cy="35.53" r="2.44" />
                  <circle className="cls-19" cx="830.57" cy="64.67" r="3.98" />
                  <circle className="cls-19" cx="800.53" cy="24.28" r="3.62" />
                  <circle className="cls-19" cx="777.85" cy="72.23" r="4.81" />
                  <circle className="cls-19" cx="756.84" cy="28.23" r="3.24" />
                  <circle className="cls-19" cx="743.17" cy="23.39" r="4.99" />
                  <circle className="cls-19" cx="719.51" cy="73.94" r="1.88" />
                  <circle className="cls-19" cx="693.09" cy="68.33" r="3.87" />
                  <circle className="cls-19" cx="678.39" cy="43.44" r="3.16" />
                  <circle className="cls-19" cx="660.71" cy="75.2" r="3.24" />
                  <circle className="cls-19" cx="631.84" cy="80.66" r="4.69" />
                  <circle className="cls-19" cx="612.56" cy="80.77" r="2.84" />
                  <circle className="cls-19" cx="585.01" cy="74.03" r="3.99" />
                  <circle className="cls-19" cx="564.78" cy="44.27" r="4.81" />
                  <circle className="cls-19" cx="540.33" cy="78.73" r="1.28" />
                  <circle className="cls-19" cx="521.55" cy="41.48" r="3.43" />
                  <circle className="cls-19" cx="482.02" cy="52.99" r="1.51" />
                  <circle className="cls-19" cx="475.66" cy="31.86" r="3.74" />
                  <circle className="cls-19" cx="447.3" cy="48.86" r="4.57" />
                  <circle className="cls-19" cx="437.65" cy="42.32" r="2.35" />
                  <circle className="cls-19" cx="433.29" cy="57.49" r="2.4" />
                  <circle className="cls-19" cx="422.87" cy="54.85" r="2.58" />
                  <circle className="cls-19" cx="401.79" cy="35.53" r="2.44" />
                  <circle className="cls-19" cx="390.57" cy="64.67" r="3.98" />
                  <circle className="cls-19" cx="360.53" cy="24.28" r="3.62" />
                  <circle className="cls-19" cx="337.85" cy="72.23" r="4.81" />
                  <circle className="cls-19" cx="316.84" cy="28.23" r="3.24" />
                  <circle className="cls-19" cx="303.17" cy="23.39" r="4.99" />
                  <circle className="cls-19" cx="279.51" cy="73.94" r="1.88" />
                  <circle className="cls-19" cx="253.09" cy="68.33" r="3.87" />
                  <circle className="cls-19" cx="238.39" cy="43.44" r="3.16" />
                  <circle className="cls-19" cx="220.71" cy="75.2" r="3.24" />
                  <circle className="cls-19" cx="191.84" cy="80.66" r="4.69" />
                  <circle className="cls-19" cx="172.56" cy="80.77" r="2.84" />
                  <circle className="cls-19" cx="145.01" cy="74.03" r="3.99" />
                  <circle className="cls-19" cx="124.78" cy="44.27" r="4.81" />
                  <circle className="cls-19" cx="100.33" cy="78.73" r="1.28" />
                  <circle className="cls-19" cx="81.55" cy="41.48" r="3.43" />
                  <circle className="cls-19" cx="42.02" cy="52.99" r="1.51" />
                  <circle className="cls-19" cx="35.66" cy="31.86" r="3.74" />
                  <circle className="cls-19" cx="7.3" cy="48.86" r="4.57" />
                </g>
                <g id="or1">
                  <circle className="cls-19" cx="819.92" cy="34.91" r="1.95" />
                  <circle className="cls-19" cx="813.6" cy="83.61" r="2.48" />
                  <circle className="cls-19" cx="792.38" cy="43.32" r="1.12" />
                  <circle className="cls-19" cx="759.47" cy="33.98" r="1.6" />
                  <circle className="cls-19" cx="737.7" cy="67.38" r="3.03" />
                  <circle className="cls-19" cx="703.32" cy="25.38" r="4.14" />
                  <circle className="cls-19" cx="689.21" cy="68.97" r="1.55" />
                  <circle className="cls-19" cx="682.57" cy="38.87" r="1.72" />
                  <circle className="cls-19" cx="675.67" cy="52.82" r="3.04" />
                  <circle className="cls-19" cx="655.68" cy="33.86" r="5.01" />
                  <circle className="cls-19" cx="643.73" cy="52.61" r="2.07" />
                  <circle className="cls-19" cx="620.81" cy="37.32" r="4.19" />
                  <circle className="cls-19" cx="606.65" cy="65.37" r="3.52" />
                  <circle className="cls-19" cx="591" cy="28.56" r="1.85" />
                  <circle className="cls-19" cx="572.24" cy="69.8" r="2.13" />
                  <circle className="cls-19" cx="553.62" cy="77.24" r="1.03" />
                  <circle className="cls-19" cx="537.59" cy="26.29" r="5.13" />
                  <circle className="cls-19" cx="524.23" cy="61.34" r="4.35" />
                  <circle className="cls-19" cx="503.78" cy="62.48" r="4.46" />
                  <circle className="cls-19" cx="489.93" cy="77.44" r="4.97" />
                  <circle className="cls-19" cx="467.65" cy="60.4" r="3.54" />
                  <circle className="cls-19" cx="440.69" cy="42.59" r=".69" />
                  <circle className="cls-19" cx="379.92" cy="34.91" r="1.95" />
                  <circle className="cls-19" cx="373.6" cy="83.61" r="2.48" />
                  <circle className="cls-19" cx="352.38" cy="43.32" r="1.12" />
                  <circle className="cls-19" cx="319.47" cy="33.98" r="1.6" />
                  <circle className="cls-19" cx="297.7" cy="67.38" r="3.03" />
                  <circle className="cls-19" cx="263.32" cy="25.38" r="4.14" />
                  <circle className="cls-19" cx="249.21" cy="68.97" r="1.55" />
                  <circle className="cls-19" cx="242.57" cy="38.87" r="1.72" />
                  <circle className="cls-19" cx="235.67" cy="52.82" r="3.04" />
                  <circle className="cls-19" cx="215.68" cy="33.86" r="5.01" />
                  <circle className="cls-19" cx="203.73" cy="52.61" r="2.07" />
                  <circle className="cls-19" cx="180.81" cy="37.32" r="4.19" />
                  <circle className="cls-19" cx="166.65" cy="65.37" r="3.52" />
                  <circle className="cls-19" cx="151" cy="28.56" r="1.85" />
                  <circle className="cls-19" cx="132.24" cy="69.8" r="2.13" />
                  <circle className="cls-19" cx="113.62" cy="77.24" r="1.03" />
                  <circle className="cls-19" cx="97.59" cy="26.29" r="5.13" />
                  <circle className="cls-19" cx="84.23" cy="61.34" r="4.35" />
                  <circle className="cls-19" cx="63.78" cy="62.48" r="4.46" />
                  <circle className="cls-19" cx="49.93" cy="77.44" r="4.97" />
                  <circle className="cls-19" cx="27.65" cy="60.4" r="3.54" />
                  <circle className="cls-19" cx=".69" cy="42.59" r=".69" />
                </g>
              </g>
              <g id="gre">
                <g id="gr4">
                  <g>
                    <circle className="cls-20" cx="865.08" cy="24.08" r=".84" />
                    <circle className="cls-20" cx="849.61" cy="45.3" r="1.71" />
                    <circle className="cls-20" cx="829.29" cy="47.28" r="3.32" />
                    <circle className="cls-20" cx="806.77" cy="22.9" r="2.23" />
                    <circle className="cls-20" cx="784.36" cy="36.55" r="1.37" />
                    <circle className="cls-20" cx="761.4" cy="78.97" r="3.54" />
                    <circle className="cls-20" cx="739.34" cy="48.11" r="4.08" />
                    <circle className="cls-20" cx="711.03" cy="48.93" r="3.01" />
                    <circle className="cls-20" cx="685.6" cy="62.55" r="4.45" />
                    <circle className="cls-20" cx="670.39" cy="28.3" r="4.81" />
                    <circle className="cls-20" cx="652.05" cy="54.24" r="1.44" />
                    <circle className="cls-20" cx="632.17" cy="75.58" r="3.48" />
                    <circle className="cls-20" cx="620.34" cy="22.77" r="1" />
                    <circle className="cls-20" cx="594.72" cy="55.9" r="1.32" />
                    <circle className="cls-20" cx="579.53" cy="62.27" r="1.11" />
                    <circle className="cls-20" cx="557.79" cy="56.56" r="4.81" />
                    <circle className="cls-20" cx="537.38" cy="52.03" r="4.9" />
                    <circle className="cls-20" cx="515.44" cy="60.56" r="4.5" />
                    <circle className="cls-20" cx="505.27" cy="70.63" r="2.9" />
                    <circle className="cls-20" cx="484.49" cy="38.37" r="3.05" />
                    <circle className="cls-20" cx="460.35" cy="25.47" r="4.95" />
                  </g>
                  <g>
                    <circle className="cls-20" cx="425.08" cy="24.08" r=".84" />
                    <circle className="cls-20" cx="409.61" cy="45.3" r="1.71" />
                    <circle className="cls-20" cx="389.29" cy="47.28" r="3.32" />
                    <circle className="cls-20" cx="366.77" cy="22.9" r="2.23" />
                    <circle className="cls-20" cx="344.36" cy="36.55" r="1.37" />
                    <circle className="cls-20" cx="321.4" cy="78.97" r="3.54" />
                    <circle className="cls-20" cx="299.34" cy="48.11" r="4.08" />
                    <circle className="cls-20" cx="271.03" cy="48.93" r="3.01" />
                    <circle className="cls-20" cx="245.6" cy="62.55" r="4.45" />
                    <circle className="cls-20" cx="230.39" cy="28.3" r="4.81" />
                    <circle className="cls-20" cx="212.05" cy="54.24" r="1.44" />
                    <circle className="cls-20" cx="192.17" cy="75.58" r="3.48" />
                    <circle className="cls-20" cx="180.34" cy="22.77" r="1" />
                    <circle className="cls-20" cx="154.72" cy="55.9" r="1.32" />
                    <circle className="cls-20" cx="139.53" cy="62.27" r="1.11" />
                    <circle className="cls-20" cx="117.79" cy="56.56" r="4.81" />
                    <circle className="cls-20" cx="97.38" cy="52.03" r="4.9" />
                    <circle className="cls-20" cx="75.44" cy="60.56" r="4.5" />
                    <circle className="cls-20" cx="65.27" cy="70.63" r="2.9" />
                    <circle className="cls-20" cx="44.49" cy="38.37" r="3.05" />
                    <circle className="cls-20" cx="20.35" cy="25.47" r="4.95" />
                  </g>
                </g>
                <g id="gr3">
                  <g>
                    <circle className="cls-20" cx="870.9" cy="46.29" r="4.86" />
                    <circle className="cls-20" cx="834.32" cy="59.21" r="3.44" />
                    <circle className="cls-20" cx="811.8" cy="63.89" r="4.93" />
                    <circle className="cls-20" cx="790.76" cy="27.71" r="1.7" />
                    <circle className="cls-20" cx="767.24" cy="55.69" r="3.35" />
                    <circle className="cls-20" cx="747.36" cy="58.86" r="1.23" />
                    <circle className="cls-20" cx="728.1" cy="72.43" r="2.48" />
                    <circle className="cls-20" cx="707.44" cy="69.36" r="2.78" />
                    <circle className="cls-20" cx="681.55" cy="78.69" r="2.07" />
                    <circle className="cls-20" cx="655.8" cy="47.56" r="2.34" />
                    <circle className="cls-20" cx="644.4" cy="77.13" r="3.17" />
                    <circle className="cls-20" cx="624.5" cy="46.52" r="3.74" />
                    <circle className="cls-20" cx="602.83" cy="30.01" r="3.28" />
                    <circle className="cls-20" cx="582.69" cy="35.56" r="1.24" />
                    <circle className="cls-20" cx="565.51" cy="47.09" r="1.01" />
                    <circle className="cls-20" cx="550.66" cy="67.64" r="1.91" />
                    <circle className="cls-20" cx="532.01" cy="49.72" r="3.84" />
                    <circle className="cls-20" cx="512.6" cy="52.69" r="3.99" />
                    <circle className="cls-20" cx="489.59" cy="76.31" r="2.99" />
                    <circle className="cls-20" cx="467.21" cy="44.07" r="4.66" />
                    <circle className="cls-20" cx="454.78" cy="54.06" r="2.29" />
                  </g>
                  <g>
                    <circle className="cls-20" cx="430.9" cy="46.29" r="4.86" />
                    <circle className="cls-20" cx="394.32" cy="59.21" r="3.44" />
                    <circle className="cls-20" cx="371.8" cy="63.89" r="4.93" />
                    <circle className="cls-20" cx="350.76" cy="27.71" r="1.7" />
                    <circle className="cls-20" cx="327.24" cy="55.69" r="3.35" />
                    <circle className="cls-20" cx="307.36" cy="58.86" r="1.23" />
                    <circle className="cls-20" cx="288.1" cy="72.43" r="2.48" />
                    <circle className="cls-20" cx="267.44" cy="69.36" r="2.78" />
                    <circle className="cls-20" cx="241.55" cy="78.69" r="2.07" />
                    <circle className="cls-20" cx="215.8" cy="47.56" r="2.34" />
                    <circle className="cls-20" cx="204.4" cy="77.13" r="3.17" />
                    <circle className="cls-20" cx="184.5" cy="46.52" r="3.74" />
                    <circle className="cls-20" cx="162.83" cy="30.01" r="3.28" />
                    <circle className="cls-20" cx="142.69" cy="35.56" r="1.24" />
                    <circle className="cls-20" cx="125.51" cy="47.09" r="1.01" />
                    <circle className="cls-20" cx="110.66" cy="67.64" r="1.91" />
                    <circle className="cls-20" cx="92.01" cy="49.72" r="3.84" />
                    <circle className="cls-20" cx="72.6" cy="52.69" r="3.99" />
                    <circle className="cls-20" cx="49.59" cy="76.31" r="2.99" />
                    <circle className="cls-20" cx="27.21" cy="44.07" r="4.66" />
                    <circle className="cls-20" cx="14.78" cy="54.06" r="2.29" />
                  </g>
                </g>
                <g id="gr2">
                  <g>
                    <circle className="cls-20" cx="874.04" cy="44.53" r="4.07" />
                    <circle className="cls-20" cx="852.72" cy="45.53" r="3.5" />
                    <circle className="cls-20" cx="841.51" cy="49.56" r="2.36" />
                    <circle className="cls-20" cx="818.35" cy="63.12" r="1.3" />
                    <circle className="cls-20" cx="795.55" cy="66.16" r="2.15" />
                    <circle className="cls-20" cx="781.26" cy="58.03" r="2.1" />
                    <circle className="cls-20" cx="755.11" cy="45.33" r="3.28" />
                    <circle className="cls-20" cx="732.41" cy="57.91" r="2.34" />
                    <circle className="cls-20" cx="721.48" cy="26.56" r="3.88" />
                    <circle className="cls-20" cx="701.1" cy="35.89" r=".95" />
                    <circle className="cls-20" cx="663.16" cy="52.44" r="1.91" />
                    <circle className="cls-20" cx="636.86" cy="81.43" r="1.14" />
                    <circle className="cls-20" cx="609.3" cy="38.34" r="4.42" />
                    <circle className="cls-20" cx="585.77" cy="44.18" r="2.86" />
                    <circle className="cls-20" cx="568.12" cy="71.33" r=".75" />
                    <circle className="cls-20" cx="540.47" cy="29.39" r="1.91" />
                    <circle className="cls-20" cx="525.6" cy="23.49" r="3.54" />
                    <circle className="cls-20" cx="493.05" cy="29.11" r="2.82" />
                    <circle className="cls-20" cx="479.61" cy="42.94" r="3.93" />
                    <circle className="cls-20" cx="447" cy="74.97" r="4.75" />
                  </g>
                  <g>
                    <circle className="cls-20" cx="434.04" cy="44.53" r="4.07" />
                    <circle className="cls-20" cx="412.72" cy="45.53" r="3.5" />
                    <circle className="cls-20" cx="401.51" cy="49.56" r="2.36" />
                    <circle className="cls-20" cx="378.35" cy="63.12" r="1.3" />
                    <circle className="cls-20" cx="355.55" cy="66.16" r="2.15" />
                    <circle className="cls-20" cx="341.26" cy="58.03" r="2.1" />
                    <circle className="cls-20" cx="315.11" cy="45.33" r="3.28" />
                    <circle className="cls-20" cx="292.41" cy="57.91" r="2.34" />
                    <circle className="cls-20" cx="281.48" cy="26.56" r="3.88" />
                    <circle className="cls-20" cx="261.1" cy="35.89" r=".95" />
                    <circle className="cls-20" cx="223.16" cy="52.44" r="1.91" />
                    <circle className="cls-20" cx="196.86" cy="81.43" r="1.14" />
                    <circle className="cls-20" cx="169.3" cy="38.34" r="4.42" />
                    <circle className="cls-20" cx="145.77" cy="44.18" r="2.86" />
                    <circle className="cls-20" cx="128.12" cy="71.33" r=".75" />
                    <circle className="cls-20" cx="100.47" cy="29.39" r="1.91" />
                    <circle className="cls-20" cx="85.6" cy="23.49" r="3.54" />
                    <circle className="cls-20" cx="53.05" cy="29.11" r="2.82" />
                    <circle className="cls-20" cx="39.61" cy="42.94" r="3.93" />
                    <circle className="cls-20" cx="7" cy="74.97" r="4.75" />
                  </g>
                </g>
                <g id="gr1">
                  <g>
                    <circle className="cls-20" cx="876.8" cy="35.49" r="3.2" />
                    <circle className="cls-20" cx="857.69" cy="79.98" r="3.8" />
                    <circle className="cls-20" cx="845.17" cy="69.76" r="1.14" />
                    <circle className="cls-20" cx="822.27" cy="55" r="2.7" />
                    <circle className="cls-20" cx="799.1" cy="69.3" r="1.93" />
                    <circle className="cls-20" cx="773.02" cy="65.23" r="5.09" />
                    <circle className="cls-20" cx="742.52" cy="69.94" r="4.58" />
                    <circle className="cls-20" cx="716.82" cy="80.05" r=".68" />
                    <circle className="cls-20" cx="693.34" cy="42.13" r="1.92" />
                    <circle className="cls-20" cx="675.65" cy="35.28" r="1.7" />
                    <circle className="cls-20" cx="640.19" cy="30.76" r="3.36" />
                    <circle className="cls-20" cx="628.79" cy="57.87" r="2.56" />
                    <circle className="cls-20" cx="612.91" cy="57.7" r="1.15" />
                    <circle className="cls-20" cx="588.39" cy="73.27" r="4.9" />
                    <circle className="cls-20" cx="575.26" cy="47.19" r="2.04" />
                    <circle className="cls-20" cx="544.28" cy="55.49" r="4.72" />
                    <circle className="cls-20" cx="518.52" cy="75.25" r="4.6" />
                    <circle className="cls-20" cx="499.83" cy="25.42" r="4.51" />
                    <circle className="cls-20" cx="472.11" cy="23.13" r="1.81" />
                    <circle className="cls-20" cx="442.96" cy="33.89" r="2.96" />
                  </g>
                  <g>
                    <circle className="cls-20" cx="436.8" cy="35.49" r="3.2" />
                    <circle className="cls-20" cx="417.69" cy="79.98" r="3.8" />
                    <circle className="cls-20" cx="405.17" cy="69.76" r="1.14" />
                    <circle className="cls-20" cx="382.27" cy="55" r="2.7" />
                    <circle className="cls-20" cx="359.1" cy="69.3" r="1.93" />
                    <circle className="cls-20" cx="333.02" cy="65.23" r="5.09" />
                    <circle className="cls-20" cx="302.52" cy="69.94" r="4.58" />
                    <circle className="cls-20" cx="276.82" cy="80.05" r=".68" />
                    <circle className="cls-20" cx="253.34" cy="42.13" r="1.92" />
                    <circle className="cls-20" cx="235.65" cy="35.28" r="1.7" />
                    <circle className="cls-20" cx="200.19" cy="30.76" r="3.36" />
                    <circle className="cls-20" cx="188.79" cy="57.87" r="2.56" />
                    <circle className="cls-20" cx="172.91" cy="57.7" r="1.15" />
                    <circle className="cls-20" cx="148.39" cy="73.27" r="4.9" />
                    <circle className="cls-20" cx="135.26" cy="47.19" r="2.04" />
                    <circle className="cls-20" cx="104.28" cy="55.49" r="4.72" />
                    <circle className="cls-20" cx="78.52" cy="75.25" r="4.6" />
                    <circle className="cls-20" cx="59.83" cy="25.42" r="4.51" />
                    <circle className="cls-20" cx="32.11" cy="23.13" r="1.81" />
                    <circle className="cls-20" cx="2.96" cy="33.89" r="2.96" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="bialasy">
          <path
            className="cls-23"
            d="m650.12,9.06h-35.46c-1.21,0-2.25.7-2.76,1.7-3.43,1.16-5.91,4.4-5.91,8.22v69.04c0,4.78,3.89,8.67,8.67,8.67h32.98c.57.75,1.46,1.25,2.48,1.25,5.47,0,9.92-4.45,9.92-9.92V18.98c0-5.47-4.45-9.92-9.92-9.92Z"
          />
          <path
            className="cls-23"
            d="m968.8,93.04l-20.31-15.03c.16-.51.26-1.03.27-1.56V12.57c0-2.8-2.39-4.37-4.61-4.65-.13-.02-.27-.02-.4-.02h-76.61c-.9,0-1.71.37-2.29.96h-20.66c-2.05,0-3.72,1.67-3.72,3.72v62.75c-.06.03-.12.07-.18.1-.11.06-.22.12-.32.19-.02.01-.04.02-.06.03l-22.11,16.36c-1.12.83-1.58,2.28-1.15,3.6s1.67,2.22,3.06,2.22l106.84.11h0c1.1,0,2.08-.56,2.66-1.4h38.41c.84,0,1.58-.54,1.84-1.33.26-.79-.02-1.67-.69-2.16Z"
          />
          <path
            className="cls-23"
            d="m420.56,12.41c-1.07,0-2.01.53-2.59,1.33-9.86.3-16.44,2.39-20.55,6.52-5.61,5.63-5.57,14.17-5.54,23.2,0,.77,0,1.56,0,2.35,0,7.85,2.54,16.38,6.96,23.4,4.6,7.29,10.65,12.12,17.11,13.68v6.55c0,2.84,2.31,5.15,5.15,5.15,2.21,0,4.1-1.41,4.83-3.37.75-.26,1.3-.97,1.3-1.82v-5.83c.14-.03.29-.06.43-.11,12.8-4.61,22.82-21.13,22.84-37.63,0,0,0,0,0,0v-.48c0-8.52,0-16.57-3.82-22.72-4.33-6.96-12.63-10.2-26.12-10.2Z"
          />
        </g>
        <g id="ikony">
          <path
            className="cls-5"
            d="m614.66,12.17h35.46c3.76,0,6.81,3.05,6.81,6.81v69.04c0,3.76-3.05,6.81-6.81,6.81"
          />
          <line className="cls-16" x1="608.52" y1="78.75" x2="657.2" y2="78.75" />
          <rect className="cls-9" x="607.86" y="12.17" width="49.07" height="82.66" rx="6.81" ry="6.81" />
          <circle className="cls-21" cx="632.31" cy="86.02" r="4.26" />
          <polyline className="cls-10" points="617.88 50.65 626.25 57.13 637.74 45.07 646.45 52.22" />
          <circle className="cls-22" cx="626.25" cy="57.13" r="2.54" />
          <circle className="cls-22" cx="637.97" cy="45.52" r="2.54" />
          <circle className="cls-22" cx="646.67" cy="52.22" r="2.54" />
          <circle className="cls-22" cx="617.21" cy="50.54" r="2.54" />
          <g>
            <path className="cls-7" d="m867.14,11.12h76.61s1.78.22,1.78,1.45v63.89s-.03,1.78-1.78,1.78h-42.89" />
            <polyline className="cls-7" points="841.84 78.24 819.74 94.6 926.58 94.71" />
            <line className="cls-7" x1="841.84" y1="78.24" x2="852.89" y2="78.24" />
            <g>
              <rect className="cls-6" x="842.41" y="10.79" width="103.12" height="67.45" rx="1.78" ry="1.78" />
              <polygon className="cls-14" points="819.74 94.6 967.65 94.6 945.54 78.24 841.84 78.24 819.74 94.6" />
            </g>
            <g>
              <polyline className="cls-11" points="893.79 45.39 904.19 53.44 918.48 38.45 929.31 47.33" />
              <circle className="cls-22" cx="904.19" cy="53.44" r="3.15" />
              <circle className="cls-22" cx="918.76" cy="39.01" r="3.15" />
              <circle className="cls-22" cx="929.58" cy="47.33" r="3.15" />
              <circle className="cls-22" cx="892.96" cy="45.25" r="3.15" />
            </g>
            <g id="b">
              <g id="c">
                <path
                  className="cls-15"
                  d="m862.8,44.32c-.22,1.07-.36,1.72-.91,2.39l-9,3.57c-.84.37-1.49,1.08-1.78,1.96-.46,1.4-1.09,5.73-1.43,9.63"
                />
                <path className="cls-15" d="m860.52,47.32s-.04,0-.09-.02" />
                <path className="cls-15" d="m860.63,25.74c-7.39,6.36-3.18,16.6-3.35,18.91-.06.88.71,1.4,1.51,1.85" />
              </g>
              <g id="d">
                <path
                  className="cls-1"
                  d="m882.17,61.87c-.34-3.9-.96-8.23-1.43-9.63-.29-.88-.94-1.59-1.78-1.96l-9-3.57c-.55-.68-.69-1.32-.91-2.39"
                />
                <path
                  className="cls-1"
                  d="m862.8,44.32c-.22,1.07-.36,1.72-.91,2.39l-9,3.57c-.84.37-1.49,1.08-1.78,1.96-.46,1.4-1.09,5.73-1.43,9.63"
                />
                <path
                  className="cls-1"
                  d="m871.22,25.75h-.01c-1.06-1.06-2.72-1.83-5.3-1.83-2.57,0-4.23.77-5.3,1.82h-.01"
                />
                <path
                  className="cls-1"
                  d="m858.99,33.78c4.78-.71,8.03-1.5,10.31-5.17.05-.09.16-.14.26-.12.11.01.19.09.22.19.43,1.36,1.6,4.68,3.04,5.4h0c-.57,5.32-1.5,8.2-4.1,10.51-.6.49-1.08.72-1.8.83l-.15.02c-.16.02-.29.05-.43.06-.13.01-.26.02-.43.02-.33,0-.53-.03-.86-.08l-.15-.02c-.73-.11-1.2-.35-1.8-.83-2.64-2.35-3.56-5.3-4.13-10.8h0Z"
                />
                <path className="cls-1" d="m871.49,47.32h0" />
                <path className="cls-1" d="m871.22,25.74c7.39,6.36,3.18,16.6,3.35,18.91.06.89-.69,1.42-1.46,1.86" />
                <path className="cls-1" d="m860.52,47.32s-.04,0-.09-.02" />
                <path className="cls-2" d="m860.63,25.74c-7.39,6.36-3.18,16.6-3.35,18.91-.06.88.71,1.4,1.51,1.85" />
                <path
                  className="cls-1"
                  d="m861.7,46.78l.22,1.96c.04.29.19.53.33.79.56.98,1.49,2.09,2.56,3.24.28.3.68.47,1.08.47s.8-.17,1.08-.47c1.07-1.15,2-2.27,2.56-3.24.15-.25.31-.5.33-.79l.22-1.97"
                />
              </g>
            </g>
          </g>
          <path className="cls-13" d="m435.33,50.96h-29.5" />
          <path className="cls-8" d="m447.29,45.81c0-16.68.26-30.19-26.73-30.19" />
          <path className="cls-8" d="m426.58,80.43c11.9-4.28,20.71-20.19,20.71-34.66" />
          <path className="cls-13" d="m425.3,89.4v-8.55" />
          <path
            className="cls-17"
            d="m435.33,50.96h-29.5c-2.84,0-5.19-2.33-5.19-5.19v-14.72c0-2.84,2.33-5.19,5.19-5.19h29.5c2.84,0,5.19,2.33,5.19,5.19v14.72c0,2.9-2.33,5.19-5.19,5.19Z"
          />
          <path
            className="cls-4"
            d="m425.3,80.84c12.48-3.5,21.99-20.22,21.99-35.07,0-16.63.26-30.14-26.73-30.14h-.01c-28.19,0-26.73,13.52-26.73,30.19,0,15.3,10.07,32.57,23.1,35.33"
          />
          <path
            className="cls-17"
            d="m416.92,77.83v11.61c0,2.33,1.85,4.18,4.18,4.18s4.18-1.85,4.18-4.18v-11.57l-8.37-.04Z"
          />
          <path className="cls-12" d="m396.15,57.09l13.3,1.21c2.37.21,4.18,2.41,4.14,5.01l-.21,16.11" />
          <path className="cls-12" d="m444.95,57.09l-12.44,1.21c-2.2.21-3.89,2.41-3.85,5.01l.21,15.24" />
        </g>
      </svg>
    </div>
  );
};

export default AppLoader;
