// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1vuv6{width:100%;padding:16px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._1vuv6>svg{width:100%}@media (min-width:1024px){._1vuv6>svg{width:auto}}", ""]);
// Exports
exports.locals = {
	"AppLoader": "_1vuv6"
};
module.exports = exports;
