// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _1J0Nn{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}@keyframes _1J0Nn{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}._1c1Ax{width:100%;height:60px;height:6rem;position:relative}._2U6Ni{position:relative;top:30px;top:3rem}._1ch6H{width:1px;width:.1rem;height:10px;height:1rem;position:absolute;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%)}.odl3F{height:10px;height:1rem;background:#95a1bd}._2_PTG{-webkit-transform:translate(-50%,5.1rem);-ms-transform:translate(-50%,5.1rem);transform:translate(-50%,5.1rem);position:absolute;font-weight:500;font-size:12px;font-size:1.2rem;color:#0a385a;white-space:nowrap}", ""]);
// Exports
exports.locals = {
	"dailyHoursDistributionAxis": "_1c1Ax",
	"dailyHoursDistributionAxis__inner": "_2U6Ni",
	"dailyHoursDistributionAxis__indicator": "_1ch6H",
	"dailyHoursDistributionAxis__line": "odl3F",
	"dailyHoursDistributionAxis__time": "_2_PTG",
	"fading": "_1J0Nn"
};
module.exports = exports;
