import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import last from 'lodash/last';
import get from 'lodash/get';
import { SECONDS_IN_DAY } from 'helpers/datetime';


export const getStackedData = (data: Record<string, number | string>[], width: number) => {
  const readingStackBaseWidth = 30;

  const sortedReadings = sortBy(data, 'timestamp');
  let processedReadings = [];
  let stackedData = [];
  let separateData = [];
  sortedReadings.forEach((reading, index) => {

    if (includes(processedReadings, reading)) {
      return;
    }

    const { timestamp } = reading;
    const nextReading = sortedReadings[index + 1];


    // Check if there was a stack before
    const lastStack = last(stackedData);

    if (lastStack) {
      const lastStackedReading = last(lastStack);
      const lastStackedReadingTimestamp = get(lastStackedReading, 'timestamp') || 0;
      const tDiff = +timestamp - +lastStackedReadingTimestamp;
      const shouldStack = SECONDS_IN_DAY / tDiff > width / (readingStackBaseWidth + (lastStack.length - 1) * 2);

      if (lastStackedReadingTimestamp && shouldStack) {
        lastStack.push(reading);
        processedReadings.push(reading);
        return;
      }
    }


    const nextTimestamp = nextReading ? nextReading.timestamp : 0;
    const timeDiff = +nextTimestamp - +timestamp;
    if (SECONDS_IN_DAY / timeDiff < width / readingStackBaseWidth) {
      separateData = [...separateData, reading];
    } else {
      stackedData = [...stackedData, [reading]];
    }
    processedReadings = [...processedReadings, reading];

  });

  return {
    stackedData,
    separateData,
  };
};
