import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/Form/Button';
import * as actions from '../../../actions';
import messages from '../../../messages';
import SettingsForm from './SettingsForm';
import ExportData from './ExportData';


class ProfileSettings extends React.PureComponent {

  static propTypes = {
    // Implicit actions
    onChangePassword: PropTypes.func,
    onEdit          : PropTypes.func,
  };


  renderContourIdActions() {
    const className = 'btn--block m-0 text--primary text--normal';
    return (
      <div className="row">
        <div className="col-6" data-testid="change-account-information">
          <Button
            styleModifier="transparent"
            labelMessage={messages.buttons.changeAccountInformation}
            className={className}
            onClick={this.props.onEdit}
          />
        </div>
        <div className="col-6" data-testid="change-password">
          <Button
            styleModifier="transparent"
            labelMessage={messages.buttons.changePassword}
            className={className}
            onClick={this.props.onChangePassword}
          />
        </div>
      </div>
    );
  }


  render() {
    return (
      <div className="row">
        <div className="col-6 pr-10">
          <SettingsForm />
          <hr className="form-divider mt-7 mb-4" />
          { this.renderContourIdActions() }
        </div>
        <div className="col-6 pl-10"><ExportData /></div>
      </div>
    );
  }

}


const mapDispatchToProps = (dispatch) => ({
  onChangePassword: () => dispatch(actions.changeAidPassword()),
  onEdit          : () => dispatch(actions.editAidAccount()),
});


const ConnectedProfileSettings = connect(
  null, // mapStateToProps,
  mapDispatchToProps,
)(ProfileSettings);


export default ConnectedProfileSettings;
