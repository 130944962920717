import React, { FC, useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import GrayStack from 'svg/gray-stack.svg';
import BlueStack from 'svg/blue-stack.svg';
import { setMeasurement } from 'modules/PatientResults/actions';
import { getMeasurement } from 'modules/PatientResults/selectors';
import { useAction } from 'hooks';
import StackTooltip from './StackTooltip';
import { StackProps } from './types';
import styles from './Stack.pcss';


const Stack: FC<StackProps> = ({ data, tooltipType }) => {
  useStyles(styles);
  const measurement = useSelector(getMeasurement);
  const setMeasurementElem = useAction(setMeasurement);
  const [isOpen, setIsOpen] = useState(false);
  const counterClassNames = cn(
    styles.stack__counter,
    { [styles['stack__counter--white']]: isOpen },
  );

  const renderMarker = () => {
    if (tooltipType === 'measurement') {
      return isOpen ? <BlueStack /> : <GrayStack />;
    }
    // eslint-disable-next-line max-len
    return <div className={cn(styles.stack__circle, styles[`${isOpen ? 'stack__circle--blue' : 'stack__circle--gray'}`])} />;
  };

  const onStackClickHandler = () => {
    setMeasurementElem(data[0]);
    setIsOpen(true);
  };

  const onTooltipCloseHandler = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (measurement && measurement.type === 'normal') {
      setMeasurementElem(measurement);
    }
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={styles.stack} onClick={onStackClickHandler}>
      { renderMarker() }
      <span className={counterClassNames}>{ data.length }</span>
      { isOpen && <StackTooltip onTooltipClose={onTooltipCloseHandler} data={data} tooltipType={tooltipType} /> }
    </div>
  );
};

export default Stack;
