// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3nLH-{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:8px;font-size:12px;font-size:1.2rem}._1vliD{font-weight:500;color:#6f7ea2}._1vliD,._3jMUI{font-size:12px;font-size:1.2rem;line-height:18px;line-height:1.8rem;white-space:nowrap}._3jMUI{font-weight:700;color:#0a385a}._3jMUI span{font-weight:500;color:#6f7ea2}", ""]);
// Exports
exports.locals = {
	"dataList__elem": "_3nLH-",
	"dataList__label": "_1vliD",
	"dataList__value": "_3jMUI"
};
module.exports = exports;
