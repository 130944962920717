import { MODULE_ID } from './constants';


export const SET_MODE = `${MODULE_ID}/SET_MODE`;
export const SET_DEVICE_MODE = `${MODULE_ID}/SET_DEVICE_MODE`;
export const SET_CALCULATION_FORMULA = `${MODULE_ID}/SET_CALCULATION_FORMULA`;
export const SET_AGGREGATE_BY = `${MODULE_ID}/SET_AGGREGATE_BY`;
export const SET_GROUP_BY = `${MODULE_ID}/SET_GROUP_BY`;
export const CHANGE_FROM_IMPORTS = `${MODULE_ID}/CHANGE_FROM_IMPORTS`;
export const CLEAR_FROM_IMPORTS = `${MODULE_ID}/CLEAR_FROM_IMPORTS`;
export const SET_IS_TREND_CHART_LINE_ENABLED = `${MODULE_ID}/SET_IS_TREND_CHART_LINE_ENABLED`;
export const SET_DASHBOARD_LAYOUT = `${MODULE_ID}/SET_DASHBOARD_LAYOUT`;
export const SET_RESULTS_IN_PROGRESS = `${MODULE_ID}/SET_RESULTS_IN_PROGRESS`;

export const DUMP_REPORTS_STATE = `${MODULE_ID}/DUMP_REPORTS_STATE`;
export const STORE_SELECTED_REPORT_TYPES = `${MODULE_ID}/STORE_SELECTED_REPORT_TYPES`;
export const SET_GESTATIONAL_REPORT_SETTINGS = `${MODULE_ID}/SET_GESTATIONAL_REPORT_SETTINGS`;
export const SET_CLICKED_MEASUREMENT = `${MODULE_ID}/SET_CLICKED_MEASUREMENT`;
