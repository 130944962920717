import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import groupBy from 'lodash/groupBy';
import maxBy from 'lodash/maxBy';
import get from 'lodash/get';
import includes from 'lodash/includes';
import cn from 'classnames';
import moment from 'moment';
import { formatTimestamp } from 'helpers/datetime';
import {
  calculateRelatedData,
  getEndTimeTooltip,
  mergeDataIntoMeals,
  prepareMeasurements,
  translateMealsIntoChartData,
} from 'helpers/relatedData';
import MetricConversions from 'libs/MetricConversions';
import Select from 'components/Form/Select';
import MealTooltip from 'components/RelatedData/MealTooltip';
import InjectionIcon from 'svg/injection.svg';
import ActivityIcon from 'svg/activity.svg';
import MealIcon from 'svg/meal.svg';
import intlShape from 'shapes/intlShape';
import Account from 'modules/Account';
import Visit from 'modules/Visit';
import * as constants from '../constants';
import messages from '../messages';
import Meal from './Meal';
import styles from './BloodGlucoseConcentrationChart.pcss';


const ResponsiveLine = lazy(() => import('@nivo/line').then((m) => ({ default: m.ResponsiveLine })));


class BloodGlucoseConcentrationChartRelatedData extends React.PureComponent {

  static getDerivedStateFromProps(props) {
    const {
      relatedData, timeSeriesResources, measurements, firstTick, lastTick,
    } = props;

    if (!relatedData && !timeSeriesResources && !measurements) {
      return {
        relatedDataGrouped : {},
        measurementsGrouped: {},
        firstTick,
        lastTick,
      };
    }

    const measurementsWeights = [];
    const measurementsHeights = [];
    measurements.forEach((measurement) => {
      switch (measurement.type) {
        case 'Weight':
          measurementsWeights.push(measurement);
          break;
        case 'Height':
          measurementsHeights.push(measurement);
          break;
        default:
          break;
      }
    });

    const chartDiff = lastTick - firstTick;
    const pointMargins = chartDiff / 125;

    const relatedDataGrouped = calculateRelatedData(relatedData, lastTick);

    relatedDataGrouped.activities = groupBy(relatedDataGrouped.activities, 'deviceDateTime');
    relatedDataGrouped.medications = groupBy(relatedDataGrouped.medications, 'deviceDateTime');

    relatedDataGrouped.activities = [
      {
        id  : 'activities',
        data: Object.keys(relatedDataGrouped.activities).map((timestamp) => ({
          x          : timestamp,
          xEnd       : maxBy(relatedDataGrouped.activities[timestamp], 'endTimestamp').endTimestamp,
          y          : 5,
          relatedData: relatedDataGrouped.activities[timestamp],
        })),
      },
      {
        id  : 'activities-end',
        data: Object.keys(relatedDataGrouped.activities).map((timestamp, index) => ({
          xStart      : timestamp,
          x           : getEndTimeTooltip(relatedDataGrouped.activities, timestamp, index, pointMargins),
          y           : 5,
          relatedData : relatedDataGrouped.activities[timestamp],
          endTimestamp: maxBy(relatedDataGrouped.activities[timestamp], 'endTimestamp').endTimestamp,
        })),
      },
    ];

    relatedDataGrouped.medications = [
      {
        id  : 'medications',
        data: Object.keys(relatedDataGrouped.medications).map((timestamp) => ({
          x          : timestamp,
          xEnd       : maxBy(relatedDataGrouped.medications[timestamp], 'endTimestamp').endTimestamp,
          y          : 5,
          relatedData: relatedDataGrouped.medications[timestamp],
        })),
      },
      {
        id  : 'medications-end',
        data: Object.keys(relatedDataGrouped.medications).map((timestamp, index) => ({
          xStart      : timestamp,
          x           : getEndTimeTooltip(relatedDataGrouped.medications, timestamp, index, pointMargins),
          y           : 5,
          relatedData : relatedDataGrouped.medications[timestamp],
          endTimestamp: maxBy(relatedDataGrouped.medications[timestamp], 'endTimestamp').endTimestamp,
        })),
      },
    ];

    const metricConversions = new MetricConversions(props.metricsUnits);
    const dataMeasurementsWeight = prepareMeasurements(
      measurementsWeights, 'weight', metricConversions, firstTick, lastTick, pointMargins,
    );
    const dataMeasurementsHeight = prepareMeasurements(
      measurementsHeights, 'height', metricConversions, firstTick, lastTick, pointMargins,
    );
    const measurementsGrouped = {
      weight: [],
      height: [],
    };

    measurementsGrouped.weight = {
      id      : 'weight',
      maxValue: get(maxBy(dataMeasurementsWeight, 'y'), 'y', 0),
      data    : dataMeasurementsWeight,
    };

    measurementsGrouped.height = {
      id      : 'height',
      maxValue: get(maxBy(dataMeasurementsHeight, 'y'), 'y', 0),
      data    : dataMeasurementsHeight,
    };

    const mealsRaw = mergeDataIntoMeals(relatedData, timeSeriesResources);
    const meals = translateMealsIntoChartData(mealsRaw, firstTick, lastTick);

    return {
      relatedDataGrouped,
      measurementsGrouped,
      meals,
      firstTick,
      lastTick,
    };

  }

  static propTypes = {
    // Implicit props
    visits        : PropTypes.arrayOf(Visit.shapes.visit),
    intl          : intlShape,
    direction     : PropTypes.string,
    firstTick     : PropTypes.number,
    isInProgress  : PropTypes.bool,
    isReadOnly    : PropTypes.bool,
    disableTooltip: PropTypes.bool,
    // Explicit props
    lastTick      : PropTypes.number,
    metricsUnits  : PropTypes.object, // @TODO: Shape
    relatedData   : PropTypes.array,
    meals         : PropTypes.array,
    measurements  : PropTypes.arrayOf(PropTypes.shape({
      measurementId: PropTypes.string.isRequired,
      timestamp    : PropTypes.number.isRequired,
      type         : PropTypes.string.isRequired,
      value        : PropTypes.number.isRequired,
    })),
    timeSeriesResources    : PropTypes.array,
    // Explicit actions
    onMouseMoveChart       : PropTypes.func,
    onSetRelatedDataTimeRef: PropTypes.func,
    onMouseLeave           : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      relatedDataGrouped: {
        activities : [],
        medications: [],
      },
      measurementsGrouped: {
        weight: [],
        height: [],
      },
      meals              : [],
      selectedOptions    : [],
      highlightedItem    : null,
      highlightedItemType: null,
    };

    this.metricConversions = new MetricConversions(props.metricsUnits);

    this.colors = {
      medications: '#19D8E6',
      foods      : '#A6E06C',
      activities : '#9982ED',
      weight     : '#f7cc6e',
      height     : '#86c9f9',
      note       : '#c7dae1',
    };
  }


  onSelectOptionMenu(selectedOption) {
    const { value } = selectedOption;
    const { selectedOptions } = this.state;

    const indexOption = selectedOptions.indexOf(value);
    if (indexOption >= 0) {
      selectedOptions.splice(indexOption, 1);
    } else {
      selectedOptions.push(value);
    }

    this.setState({ selectedOptions });
  }


  onMouseLeave(evt) {
    const { onMouseLeave } = this.props;
    if (onMouseLeave) {
      onMouseLeave(evt);
    }

    this.setState({ highlightedItem: null });
  }


  onMouseMoveChart(evt, margin) {
    const { onMouseMoveChart } = this.props;
    if (onMouseMoveChart) {
      onMouseMoveChart(evt, margin);
    }
  }


  onMouseHoverPoint(point) {
    const { data, serieId } = point;
    const { xStart, x } = data;

    const startTime = xStart || x;

    const parsedSerieId = serieId.replace('-end', '');

    if (startTime !== this.state.highlightedItem || parsedSerieId !== this.state.highlightedItemType) {
      this.setState({ highlightedItem: startTime, highlightedItemType: parsedSerieId });
    }
  }


  renderTooltip(point) {
    if (this.props.disableTooltip || this.props.isInProgress) {
      return null;
    }

    const { data, serieId } = point;
    const { x, y, xEnd, xStart, endTimestamp, yStart } = data;

    const value = yStart || y;

    const type = serieId.replace('-end', '');

    if (!value) return null;

    const { intl } = this.props;
    let title = intl.formatMessage(messages.labels[type]);
    let icon;
    let timeDiff;
    let description;


    switch (type) {
      case 'activities':
        icon = <ActivityIcon />;
        timeDiff = data.relatedData[0].value || 1;
        description = '';
        data.relatedData.forEach((item, index) => {
          if (index > 0) {
            description += '\n';
          }

          description += `${item.name}: ${item.value}`;
        });
        break;

      case 'medications':
        title = '';
        data.relatedData.forEach((item, index) => {
          if (index > 0) {
            title += ', ';
          }
          if (item.name === 'Long-acting Insulin') {
            title += `${intl.formatMessage(messages.labels.longTerm)}: ${item.value}`;
            timeDiff = 24;
          } else if (item.name === 'Fast-acting Insulin') {
            title += `${intl.formatMessage(messages.labels.fastTerm)}: ${item.value}`;
            timeDiff = 6;
          }
        });
        icon = <InjectionIcon />;
        break;

      case 'weight':
      case 'height':
        title += `: ${value}`;
        break;

      default:
        break;
    }

    const shouldShowEndDate = xStart ? (timeDiff && x) : (timeDiff && xEnd);

    const startTime = xStart || x;
    let endTime = endTimestamp || xEnd || x;
    if (data.relatedData[0]) {
      const momentTime = moment.unix(startTime).utc();
      const addedTime = momentTime.add(data.relatedData[0].value, 'm');
      endTime = moment(addedTime).unix();
    }

    return (
      <div className={styles.tooltipRelated}>
        <div>
          <div className={styles.tooltipRelated__header}>
            { icon } { title }
          </div>
          {
            description && (
              <div className={styles.tooltip__desciption}>
                { description }
              </div>
            )
          }
          <div className={styles.tooltipRelated__content}>
            <div className={styles.tooltipRelated__timeLine}>
              <div className={styles.tooltipRelated__circle} />
              <div className={styles.tooltipRelated__line} />
              { shouldShowEndDate && <div className={styles.tooltipRelated__circle} /> }
            </div>
            <div className={styles.tooltipRelated__timeRanges}>
              <p>
                { formatTimestamp(startTime, 'HH:mm DD/MM/YYYY') }
              </p>
              {
                shouldShowEndDate && (
                  <>
                    <p className={styles.tooltipRelated__timeRangesHours}>
                      { `${timeDiff} ${intl.formatMessage(messages.labels.minutes)}` }
                    </p>
                    <p>
                      { formatTimestamp(endTime, 'HH:mm DD/MM/YYYY') }
                    </p>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }


  renderMealTooltip(point) {
    const meal = get(point, 'data.meal');

    if (this.props.disableTooltip || this.props.isInProgress || !meal) {
      return null;
    }

    return <MealTooltip meal={meal} />;
  }


  renderPoint(point, type, layer) {
    const { xScale } = layer;
    const { highlightedItem, highlightedItemType } = this.state;
    const { x, y, xEnd, xStart } = point.data;
    if (xStart) {
      return null;
    }

    const parsedType = type.replace('-end', '');

    const isHighlighted = highlightedItem && x
      ? highlightedItem.toString() === x.toString() && parsedType === highlightedItemType
      : false;
    const highlightedStyle = isHighlighted ? { border: '1px solid black', strokeWidth: 1, stroke: 'black' } : {};
    const width = Math.max(xScale(xEnd) - xScale(x), 1);
    const pos = xScale(xStart || x);
    return (
      <g key={`${type}-${x}-${y}`} transform={`translate(${pos},15)`}>
        <rect
          rx="4"
          y="0"
          opacity={0.75}
          width={width}
          height={16}
          fill={this.colors[type]}
          style={{ pointerEvents: 'none', ...highlightedStyle }}
        />
      </g>
    );
  }


  renderRanges(layer, type) {
    const { points } = layer;
    return points.map((point) => this.renderPoint(point, type, layer));
  }


  renderCarb(props) {
    const { x, meal } = props.datum;
    if (!meal) {
      return null;
    }

    const { highlightedItem, highlightedItemType } = this.state;

    const isHighlighted = highlightedItem && x
      && highlightedItem.toString() === x.toString()
      && highlightedItemType === 'carb';
    const highlightedStyle = isHighlighted ? { border: '1px solid black', strokeWidth: 1, stroke: 'black' } : {};

    return (
      <Meal
        carbs={meal.carbs}
        imageHref={meal.image}
        style={{ pointerEvents: 'none', ...highlightedStyle }}
      />
    );
  }


  renderMeasurementPoint(point, type, layer) {
    const { xScale, yScale } = layer;
    const { x, y, yEnd, xEnd, prevY = 0, xStart } = point.data;
    const { highlightedItem, highlightedItemType } = this.state;
    if (xStart) {
      return null;
    }
    const width = Math.max(xScale(xEnd || x) - xScale(x), 0) + 1;
    const height = y > 0 ? yScale(y) : 0;
    const nextDataIsBigger = yEnd && y > yEnd;
    const rightRadius = nextDataIsBigger ? Math.min(4, yScale(y) - yScale(yEnd), xScale(xEnd || x) - xScale(x)) : 0;
    const prevDataIsBigger = prevY && prevY > y;
    const leftRadius = prevDataIsBigger ? 0 : Math.min(4, yScale(y) - yScale(prevY), xScale(xEnd || x) - xScale(x));

    const parsedType = type.replace('-end', '');

    const xTimestamp = xStart || x;
    const pos = xScale(xTimestamp);
    const isHighlighted = highlightedItem && xTimestamp && height > 0
      ? highlightedItem.toString() === xTimestamp.toString() && parsedType === highlightedItemType
      : false;

    return (
      <g key={`${type}-${x}-${y}`} transform={`translate(${pos},0)`}>
        <path
          stroke="black"
          strokeWidth={isHighlighted ? 1 : 0}
          d={
            `M 0 ${height} V
            ${leftRadius} C 0 0 ${leftRadius} 0 ${leftRadius} 0 Q ${rightRadius} 0
            ${width - rightRadius - leftRadius} 0 Q ${width} 0 ${width} ${rightRadius} V ${height} z`
          }
          opacity={0.75}
          fill={this.colors[type]}
        />
      </g>
    );
  }


  renderMeasurementPoints(layer, type) {
    const { points } = layer;
    return points.map((point) => this.renderMeasurementPoint(point, type, layer));
  }


  renderMeals() {
    const { isInProgress, direction, firstTick, lastTick } = this.props;
    const { meals, selectedOptions } = this.state;

    if (!selectedOptions.includes('carbs')) {
      return null;
    }

    const margin = {
      top   : 0,
      right : 50,
      bottom: 0,
      left  : 50,
    };

    const title = <FormattedMessage {...messages.labels.carbs} />;
    const icon = <MealIcon />;
    const unit = <FormattedMessage {...messages.labels.nutrientUnit} />;

    return (
      <div>
        <p className={styles.relatedLabel}>{ title } ({ unit })</p>
        <div className={`nivoChart ${styles.root_related} ${styles.root_relatedMeals}`}>
          <div
            className={cn('nivoChart__inner', styles.root_related__inner, { fadingLoader: isInProgress })}
            onMouseMove={(e) => this.onMouseMoveChart(e, margin)}
            onMouseLeave={(e) => this.onMouseLeave(e)}
          >
            <div className={`${styles.related_iconWrapper} ${styles.related_iconWrapperFoods}`}>
              { icon }
            </div>
            <Suspense fallback={null}>
              <ResponsiveLine
                data={meals}
                margin={margin}
                layers={['points', 'mesh']}
                pointSymbol={(props) => this.renderCarb(props)}
                xScale={{ type: 'linear', min: firstTick, max: lastTick, reverse: direction === 'rtl' }}
                yScale={{ type: 'linear', min: 0, max: 1 }}
                tooltip={({ point }) => this.renderMealTooltip(point)}
                useMesh
                onMouseMove={(point) => this.onMouseHoverPoint(point)}
              />
            </Suspense>
          </div>
        </div>
      </div>
    );
  }


  renderRelated(type) {
    const { isInProgress, direction, firstTick, lastTick } = this.props;
    const { relatedDataGrouped, selectedOptions } = this.state;

    if (!selectedOptions.includes(type)) {
      return null;
    }

    const relatedLength = relatedDataGrouped[type][0].data.length;

    const margin = {
      top   : 0,
      right : 50,
      bottom: 30,
      left  : 50,
    };

    const title = <FormattedMessage {...messages.labels[type]} />;
    let icon;
    let unit;

    switch (type) {
      case 'activities':
        icon = <ActivityIcon />;
        unit = <FormattedMessage {...messages.labels.activityUnit} />;
        break;

      case 'medications':
        icon = <InjectionIcon />;
        unit = <FormattedMessage {...messages.labels.injectionUnit} />;
        break;

      default:
        break;
    }

    const relatedDataLayer = (layer) => this.renderRanges(layer, type);
    /**
     * Mesh crashes on hover if empty chart, so we turn it on only if relatedLength is greater than 0
     * https://github.com/plouc/nivo/issues/2597
     */
    return (
      <div>
        <p className={styles.relatedLabel}>{ title } ({ unit })</p>
        <div className={`nivoChart ${styles.root_related}`}>
          <div
            className={cn('nivoChart__inner', styles.root_related__inner, { fadingLoader: isInProgress })}
            onMouseMove={(e) => this.onMouseMoveChart(e, margin)}
            onMouseLeave={(e) => this.onMouseLeave(e)}
          >
            <div className={styles.related_iconWrapper}>
              { icon }
            </div>
            <Suspense fallback={null}>
              <ResponsiveLine
                data={relatedDataGrouped[type]}
                margin={margin}
                layers={[relatedDataLayer, relatedLength ? 'mesh' : null]}
                xScale={{ type: 'linear', min: firstTick, max: lastTick, reverse: direction === 'rtl' }}
                yScale={{ type: 'linear', min: 0, max: 10 }}
                tooltip={({ point }) => this.renderTooltip(point)}
                useMesh
                onMouseMove={(point) => this.onMouseHoverPoint(point)}
              />
            </Suspense>
          </div>
        </div>
      </div>
    );
  }


  renderMeasurements(type) {
    const { isInProgress, direction, onMouseMoveChart, firstTick, lastTick } = this.props;
    const { measurementsGrouped, selectedOptions } = this.state;

    if (!selectedOptions.includes(type)) {
      return null;
    }

    const margin = {
      top   : 15,
      right : 50,
      bottom: 30,
      left  : 50,
    };

    const tickValues = [0];
    if (measurementsGrouped[type].maxValue) {
      tickValues.push(measurementsGrouped[type].maxValue);
    }

    const axisY = {
      tickSize    : 0,
      tickPadding : 18,
      tickRotation: 0,
      tickValues,
    };

    const theme = {
      text: {
        fontSize: 12,
        fill    : '#6F7EA2',
      },
    };

    const measurementsLayer = (layer) => this.renderMeasurementPoints(layer, type);

    return (
      <div>
        <p className={styles.relatedLabel}>
          <FormattedMessage {...messages.labels[type]} /> ({ this.metricConversions[type].unitSymbol })
        </p>
        <div className={`nivoChart ${styles.root_measurements}`}>
          <div
            className={cn('nivoChart__inner', styles.root_related__inner, { fadingLoader: isInProgress })}
            onMouseMove={(e) => onMouseMoveChart(e, margin)}
            onMouseLeave={(e) => this.onMouseLeave(e)}
          >
            <Suspense fallback={null}>
              <ResponsiveLine
                data={[measurementsGrouped[type]]}
                margin={margin}
                theme={theme}
                layers={['axes', measurementsLayer, 'mesh']}
                axisRight={direction === 'rtl' ? axisY : null}
                axisLeft={direction === 'rtl' ? null : axisY}
                axisTop={null}
                axisBottom={null}
                xScale={{ type: 'linear', min: firstTick, max: lastTick, reverse: direction === 'rtl' }}
                yScale={{ type: 'linear', min: 0, max: measurementsGrouped[type].maxValue }}
                tooltip={({ point }) => this.renderTooltip(point)}
                useMesh
                onMouseMove={(point) => this.onMouseHoverPoint(point)}
              />
            </Suspense>
          </div>
        </div>
      </div>
    );
  }

  renderSelectOptions() {
    const { selectedOptions } = this.state;
    const { intl } = this.props;
    return (

      <div className={styles.related__selectContainer}>
        <Select
          id="relatedData"
          optionsFrom={
            constants.RELATED_DATA_CHART_OPTIONS.map((option) => ({
              option,
              label: intl.formatMessage(messages.labels[option]),
            }))
          }
          noValueMessage={messages.placeholders.additionalChart}
          onChange={(selectedOption) => this.onSelectOptionMenu(selectedOption)}
          valueKey="option"
          labelKey="label"
          value={selectedOptions}
          className={styles.related__selectWrapper}
          multi
        />
      </div>
    );
  }


  render() {
    if (this.props.isReadOnly) {
      return null;
    }

    const { selectedOptions } = this.state;

    let className = '';
    if (selectedOptions.length > 0) {
      className += `${styles.timeSelector__chart} ${styles.timeSelector_relatedData__chart} `;
    }
    if (includes(selectedOptions, 'carbs')) {
      className += `${styles.timeSelector_relatedData__chartMeals} `;
      if (selectedOptions.length === 1) {
        className += `${styles.timeSelector_relatedData__chartMealsOnly}`;
      }
    }

    return (
      <div>
        { this.renderSelectOptions() }
        <div className={styles.related_wrapper}>
          { this.renderMeals() }
          { this.renderRelated('medications') }
          { this.renderRelated('activities') }
          { this.renderMeasurements('weight') }
          { this.renderMeasurements('height') }
          <div
            ref={(timeSelector) => { this.props.onSetRelatedDataTimeRef(timeSelector); }}
            className={className}
          />
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  metricsUnits: Account.selectors.metricsUnits(state),
});


const ConnectedBloodGlucoseConcentrationChartRelatedData = connect(
  mapStateToProps,
)(injectIntl(BloodGlucoseConcentrationChartRelatedData));


export default ConnectedBloodGlucoseConcentrationChartRelatedData;
