// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2yigQ{background-color:#fff;padding:12px;border-radius:8px;-webkit-box-shadow:0 4px 20px 0 rgba(50,66,121,.5);box-shadow:0 4px 20px 0 rgba(50,66,121,.5);position:absolute;left:50%;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);bottom:120%;min-width:172px}._2C4vo{width:12px;height:12px;fill:#30a8ff;cursor:pointer;position:absolute;top:12px;right:12px;z-index:1}", ""]);
// Exports
exports.locals = {
	"tooltip": "_2yigQ",
	"tooltip__close": "_2C4vo"
};
module.exports = exports;
